/* ==========================================================================
   Layout / Block Partenaires
   ========================================================================== */

  .blockpartners {
    @include wrapper('min');
    margin-bottom: 60px;

    @include breakpoint(tablethor) {
      margin-bottom: 100px;
    }

    .title-special {
      margin-bottom: 35px;

      @include breakpoint(tablethor) {
        margin-bottom: 50px;
      }

      &:before {
        background-image: url('images/titre-forme-orange.svg');
      }
    }

    &-blocks {
      display: block;

      .partner {
        display: block;
        margin-bottom: 35px;

        @include breakpoint(tablet) {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &-logo {
          @include breakpoint(tablet) {
            flex-basis: 40%;
            margin-left: 3%;
            margin-right: 5%;
          }

          @include breakpoint(tablethor) {
            flex-basis: 25%;
          }

          img {
            display: block;
            max-width: 100%;
            height: auto;
            margin: auto auto 20px;

            @include breakpoint(tablet) {
              margin: 0 0 15px;
            }
          }
        }

        &-text {
          @include breakpoint(tablet) {
            flex-basis: 55%;
          }

          @include breakpoint(tablethor) {
            flex-basis: 70%;
          }

          .btn {
            margin: 30px auto 0;

            @include breakpoint(tablet) {
              margin: 30px 0 0;
            }
          }
        }
      }
    }
  }
