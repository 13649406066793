/* ==========================================================================
   Layout / Block Carte Usines
   ========================================================================== */

  .blockfactories {
    @include wrapper('min');
    width: 90%;
    margin-bottom: 50px;

    @include breakpoint(phablet) {
      width: 85%;
      margin-bottom: 70px;
    }

    @include breakpoint(tablet) {
      display: flex;
      width: 100%;
    }

    @include breakpoint(tablethor) {
      width: 90%;
      margin-bottom: 130px;
    }

    .title-special {
      margin-bottom: 35px;
    }

    &-list {
      display: none;
      margin-bottom: 30px;

      @include breakpoint(phablet) {
        margin-bottom: 40px;
      }

      @include breakpoint(tablet) {
        display: block;
        flex-basis: 320px;
        width: 320px;
        margin-left: 7%;
        margin-bottom: 10px;
      }

      @include breakpoint(tablethor) {
        flex-basis: 385px;
        width: 385px;
        margin-left: 0;
        position: relative;
        left: 2%;
      }

      @include breakpoint(mediumdesktop) {
        left: 0;
      }
    }

    &-listwrapper {
      display: block;
      box-sizing: border-box;
      max-height: 370px;

      @include breakpoint(phablet) {
        max-height: 290px;
      }

      @include breakpoint(tablet) {
        max-height: 580px;
      }

      .mCustomScrollBox {
        display: block;
        width: 100%;
        box-sizing: border-box;
        padding-left: 50px;
      }

      ul li {
        list-style-type: none;
        padding: 20px 0;
        line-height: 1.5;
        border-bottom: 1px solid #adadad;

        &:first-child {
          padding-top: 0;
        }

        &.current {
          cursor: default;

          strong {
            color: $orange;
          }
        }

        strong {
          display: block;
          font-family: $font-stack-title;
          font-size: 20px;
          font-weight: 500;
          line-height: 1.35;
          text-transform: uppercase;
          transition: all $durationSmall $easeInOutCirc;
          margin-bottom: 5px;
        }

        &:hover:not(.current) {
          cursor: pointer;

          strong {
            color: $blueColor;
            transition: all $durationSmall $easeInOutCirc;
          }
        }
      }

      &.mCS_no_scrollbar {
        .mCustomScrollBox {
          padding-left: 5px;
        }
      }
    }

    &-map {
      display: block;
      margin: 0;
      min-height: 400px;
      border-radius: 0 0 0 200px;
      overflow: hidden;
      position: relative;
      z-index: 3;

      @include breakpoint(phablet) {
        border-radius: 0 0 0 300px;
      }

      @include breakpoint(tablet) {
        flex-basis: calc(93% - 320px);
        width: calc(93% - 320px);
        min-height: inherit;
        border-radius: 0 0 0 610px;
        margin-left: 3%;
      }

      @include breakpoint(tablethor) {
        flex-basis: calc(100% - 385px);
        width: calc(100% - 385px);
        height: 712px;
        margin-left: 0;
        position: relative;
        left: 6%;
      }

      @include breakpoint(mediumdesktop) {
        left: 0;
        flex-basis: calc(95% - 385px);
        width: calc(95% - 385px);
        margin-left: 5%;
      }

      #factoriesMap {
        display: block;
        width: 100%;
        height: 100%;
        min-height: 400px;

        @include breakpoint(tablet) {
          min-height: 712px;
        }
      }
    }
  }