/* ==========================================================================
   Components / Icons
   ========================================================================== */

  .icon {
    fill: $blackColor;
  }

  .icon-map {
    display: block;
    width: 30px;
    height: 40px;
    background: url('images/icone-localisation.svg') center center/100% auto no-repeat;

    &.is-selected {
      z-index: 99999!important;
    }

    .cluster-infos {
      display: block;
      width: 215px;
      box-sizing: border-box;
      padding: 13px 20px;
      background: $whiteColor;
      border-radius: 13px 0;
      border: 2px solid $orange;
      font-family: $font-stack-special;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.43;
      letter-spacing: 0;
      color: $blackColor;
      opacity: 0;
      visibility: hidden;
      transform: scale(0.5);
      transition: all $duration $easeInOutBack;
      position: absolute;
      top: -94px;
      left: -92px;
      z-index: 999999;

      &.medium {
        width: 238px;
        top: -95px;
        left: -103px;
      }

      &.large {
        width: 235px;
        top: -114px;
        left: -102px;
      }

      strong {
        display: block;
        color: $orange;
        font-family: $font-stack-title;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.2;
        text-transform: uppercase;
        margin-bottom: 10px;
      }

      .close {
        display: block;
        width: 12px;
        height: 12px;
        text-indent: -9999px;
        position: absolute;
        top: 10px;
        right: 5px;

        &:before, &:after {
          content: ' ';
          display: block;
          width: 1px;
          height: 100%;
          background: $orange;
          transition: all $duration $easeInOutQuart;
          position: absolute;
          top: 0;
          left: 0;
        }

        &:before {transform: rotate(45deg);}
        &:after {transform: rotate(-45deg);}

        &:hover {
          &:before, &:after {
            background: $blueColor;
            transition: all $duration $easeInOutQuart;
          }
        }
      }

      &:after {
        content: ' ';
        display: block;
        width: 17px;
        height: 17px;
        border-radius: 0;
        background: #fff;
        border-right: 2px solid $orange;
        border-bottom: 2px solid $orange;
        position: absolute;
        margin: auto;
        top: auto;
        bottom: -11px;
        left: 0;
        right: 0;
        transform: rotate(45deg);
        z-index: 0;
      }

      &.show {
        opacity: 1;
        visibility: visible;
        transform: scale(1);
        transition: all $duration $easeInOutBack;
      }
    }
  }

