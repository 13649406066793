/* ==========================================================================
   Page / Page Produit
   ========================================================================== */

  .products-template-default .header {
    margin-bottom: 0;
  }

  .product {
    display: block;
    margin-top: 10px;
    margin-bottom: 50px;
    min-height: calc(100vh - 542px);
    position: relative;
    z-index: 4;

    @include breakpoint(phablet) {
      margin-top: 20px;
      margin-bottom: 60px;
    }

    @include breakpoint(tablet) {
      margin-top: 30px;
    }

    @include breakpoint(tablethor) {
      margin-top: 50px;
    }

    @include breakpoint(laptop) {
      margin-top: 5px;
    }

    &-header {
      @include wrapper('min');
    }

    &-infos {
      display: block;

      @include breakpoint(tablethor) {
        display: flex;
        flex-direction: row-reverse;
        margin-bottom: 35px;
      }

      @include breakpoint(laptop) {
        margin-bottom: 80px;
      }
    }

    &-visuals {
      display: block;
      height: auto;
      margin-bottom: 50px;

      @include breakpoint(tablet) {
        margin-bottom: 70px;
      }

      @include breakpoint(tablethor) {
        flex-basis: 52%;
        width: 52%;
        max-width: 52%;
        margin-right: 3%;
        margin-bottom: 0;
      }

      @include breakpoint(laptop) {
        flex-basis: 48.5%;
        width: 48.5%;
        max-width: 48.5%;
        margin-right: 2.5%;
      }

      .slidervisuals {
        display: block;
        width: 100%;
        border-radius: 30px 0 30px 0;
        background: darken($greyWhite, 2%);
        border: 1px solid #f3f3f3;
        margin-bottom: 10px;
        position: relative;
        overflow: hidden;

        &:before {
          content: ' ';
          display: block;
          width: 70px;
          height: 70px;
          background: url('images/logo-madeinfrance.svg') center center/cover no-repeat;
          position: absolute;
          bottom: 30px;
          right: 30px;
          z-index: 1;
        }

        @include breakpoint(phablet) {
          margin-bottom: 20px;
        }
      }

      .sliderthumbs {
        .slick-track {
          margin: 0;
        }
        .slick-slide {
          border: 2px solid $whiteColor;
          transition: all $duration $easeInOutQuint;
          margin-right: 8px;
          cursor: pointer;

          @include breakpoint(phablet) {
            margin-right: 15px;
          }

          @include breakpoint(tablehtor) {
            margin-right: 20px;
          }

          &:not(.slick-current):hover {
            opacity: 0.6;
            transition: all $duration $easeInOutQuint;
          }

          &.slick-current {
            border-color: rgba(0,176,208,0.5);
          }
        }
      }

      img {
        display: block;
        width: 100%;
        height: auto;
        max-height: inherit;
      }
    }

    &-text {
      margin-bottom: 30px;

      @include breakpoint(tablet) {
        margin-bottom: 45px;
      }

      @include breakpoint(tablethor) {
        flex-basis: 45%;
        width: 45%;
        margin-bottom: 0;
      }

      @include breakpoint(laptop) {
        flex-basis: 49%;
        width: 49%;
      }

      .btn-main {
        margin: 30px auto 0;

        @include breakpoint(phablet) {
          margin: 0;
        }
      }
    }

    &-categories {
      margin-bottom: 3px;

      li {
        display: inline-block;
        vertical-align: top;
        list-style-type: none;
        margin-right: 10px;
      }

      a {
        display: block;
        font-size: 10px;
        font-family: $font-stack-title;
        font-weight: 600;
        line-height: normal;
        letter-spacing: normal;
        text-transform: uppercase;
        color: $whiteColor;
        padding: 6px 14px;
        border-radius: 5px 0 5px 0;
      }
    }

    &-title {
      @extend .h1-title;
      margin-bottom: 5px;
    }

    &-subtitle {
      @extend .h3-title;
      margin-bottom: 15px;

      @include breakpoint(phablet) {
        margin-bottom: 20px;
      }
    }

    &-desc {
      margin-bottom: 20px;

      p, span {
        font-weight: 300!important;
      }

      ul {
        margin-top: 20px;
        margin-left: 2px;

        li {
          list-style-type: none;
          font-size: 15px;
          font-weight: 600;
          color: $blackColor;
          line-height: 1.47;
          letter-spacing: normal;
          position: relative;
          padding-left: 20px;

          &:not(:last-child) {
            margin-bottom: 15px;
          }

          &:before {
            content: ' ';
            display: block;
            width: 6px;
            height: 6px;
            background: $blueColor;
            border-radius: 800px;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto 0;
          }

          h4 {
            margin: 0;
            padding: 0;
          }
        }
      }
    }

    &-uses {
      margin-bottom: 10px;

      h3 {
        @extend .h2-title;
      }

      ul {
        display: block;
        margin-left: 3px;

        @include breakpoint(phablet) {
          display: flex;
          flex-wrap: wrap;
        }

        li {
          display: block;
          list-style-type: none;
          box-sizing: border-box;
          font-size: 15px;
          font-weight: 900;
          line-height: 1.4;
          color: $blackColor;
          padding-left: 35px;
          margin-bottom: 20px;
          position: relative;

          @include breakpoint(phablet) {
            flex-basis: 50%;
            margin-bottom: 15px;

            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
              flex-basis: 43%;
            }
          }

          @include breakpoint(tablet) {
            margin-bottom: 30px;
          }

          &:before {
            content: ' ';
            display: block;
            width: 16px;
            height: 16px;
            background: url('images/icone-check.svg') center center/16px 16px no-repeat;
            position: absolute;
            top: 1px;
            left: 0;
          }
        }
      }
    }
  }