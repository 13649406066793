/* ==========================================================================
   Components / Telecommand
   ========================================================================== */

  .telecommand {
    display: none;
    width: 100%;
    text-align: center;
    position: fixed;
    margin: auto;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 8;

    @include breakpoint(tablet) {
      text-align: left;
      width: auto;
      margin: 0;
      top: 30%;
      bottom: inherit;
      left: inherit;
      right: 0;
    }

    .btn-telecomand {
      display: inline-block;
      vertical-align: top;
      white-space: nowrap;
      font-family: $font-stack-special;
      box-sizing: border-box;
      font-size: 15px;
      font-weight: 900;
      text-transform: uppercase;
      color: $whiteColor;
      border-radius: 35px 0 0 0;
      padding: 13px 15px 10px 20px;
      margin: 0;
      background: rgba(235, 100, 8, 1);
      transition: all $durationLong $easeOutExpo;

      @include breakpoint(phablet) {
        border-radius: 50px 0 0 0;
        padding: 18px 18px 13px 25px;
      }

      @include breakpoint(tablet) {
        display: block;
        width: 250px;
        padding: 19px 24px 16px;
        transform: translateX(185px);
        background: rgba(235, 100, 8, 0.6);
      }

      .icon {
        display: inline-block;
        vertical-align: top;
        width: 25px;
        height: 25px;
        fill: $whiteColor;

        @include breakpoint(phablet) {
          width: 30px;
          height: 30px;
        }

        @include breakpoint(tablet) {
          width: 35px;
          height: 35px;
          transform: rotate(0deg) translateY(0);
        }
      }

      .text {
        display: none;
        vertical-align: top;
        margin-left: 15px;
        position: relative;
        top: 6px;

        @include breakpoint(tablet) {
          display: inline-block;
        }
      }

      &.frenchv {
        @include breakpoint(tablet) {
          transform: translateX(230px);
        }

        &:hover {
          @include breakpoint(laptop) {
            transform: translateX(65px);
          }
        }
      }

      @include breakpoint(laptop) {
        &:hover {
          background: $orange;
          transform: translateX(40px);
          transition: all $durationLong $easeOutExpo;
        }
      }
    }

    .btn-brochure {
      padding: 14px 19px 9px 16px;
      background: rgba(0, 176, 205, 1);
      border-radius: 0 35px 0 0;

      @include breakpoint(phablet) {
        border-radius: 0 50px 0 0;
        padding: 18px 25px 13px 18px;
      }

      @include breakpoint(tablet) {
        width: 260px;
        margin-top: 5px;
        padding: 17px 24px 18px;
        border-radius: 0 0 0 50px;
        background: rgba(0, 176, 205, 0.6);
      }

      &.frenchv {
        @include breakpoint(tablet) {
          width: 305px;
          transform: translateX(230px);
        }

        &:hover {
          @include breakpoint(laptop) {
            transform: translateX(0px);
          }
        }
      }

      &:hover {
        background: $blueColor;

        @include breakpoint(laptop) {
          transform: translateX(0px);
        }
      }
    }
  }