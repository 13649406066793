/* ==========================================================================
   Layout / Block Suggestion Espèces
   ========================================================================== */

  .suggestspecies {
    @include wrapper('min');
    padding: 0;
    margin-bottom: 50px;
    position: relative;
    z-index: 4;

    @include breakpoint(phablet) {
      padding: 25px 0;
    }

    @include breakpoint(tablet) {
      padding: 35px 0 50px;
      margin-bottom: 80px;
    }

    &-content {
      @include breakpoint(tablet) {
        display: flex;
        justify-content: center;
      }

      .slick-prev, .slick-next {
        background-color: $greyLight;
        background-size: 50% auto;
        background-position: center center;
        border-radius: 80px;
        padding: 18px;
        top: 20%;

        @include breakpoint(phablet) {
          padding: 20px;
        }
      }

      .slick-next {
        right: -8px;
      }

      .slick-prev {
        left: -8px;
      }

      .slick-dots {
        margin-top: 15px;
        bottom: 0;
      }

      .speciecard {
        margin: 0 10px;
        box-shadow: 0 0 0 0 transparent;

        @include breakpoint(tablet) {
          flex-basis: 193px;
        }

        &-name {
          padding: 25px 20px 35px;

          @include breakpoint(tablethor) {
            padding: 25px 50px 35px;
          }
        }
      }
    }
  }