/* ==========================================================================
   Page / Page Actualités
   ========================================================================== */

  .newspage {
    @include wrapper('min');
    width: 90%;
    margin: 35px auto;
    position: relative;
    z-index: 5;

    @include breakpoint(phablet) {
      display: flex;
      flex-wrap: wrap;
      margin: 50px auto;
    }

    @include breakpoint(tablethor) {
      margin: 70px auto 50px;
    }

    .card {
      width: 100%;
      margin-bottom: 20px;

      @include breakpoint(phablet) {
        flex-basis: calc(50% - 20px);
        width: calc(50% - 20px);
        margin: 0 10px 25px;
      }

      @include breakpoint(tablet) {
        flex-basis: calc(33% - 20px);
      }

      @include breakpoint(1300px) {
        flex-basis: calc(25% - 20px);
      }
    }

    &  + .pagination {
      display: block;
      margin: auto auto 70px;
      background: transparent;
      height: auto;
      position: relative;
      top: 0;
      left: 0;
    }
  }