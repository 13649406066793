/* ==========================================================================
   Components / Keynumber Card
   ========================================================================== */

  .keycard {
    display: block;
    width: 100%;
    border-radius: 30px 0;
    background-color: $greyLight;
    padding: 40px 45px 30px;
    box-sizing: border-box;
    text-align: center;
    overflow: hidden;
    position: relative;
    margin-bottom: 20px;

    &-number {
      display: block;
      max-width: 100%;
      font-size: 60px;
      font-weight: 900;
      font-family: $font-stack-title;
      line-height: normal;
      color: $orange;
      margin-bottom: 8px;
    }

    &-title {
      display: block;
      max-width: 100%;
      font-family: $font-stack-title;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.3;
      letter-spacing: normal;
      text-align: center;
    }

    &-desc {
      max-width: 100%;
      line-height: 1.3;
      margin-top: 8px;
    }
  }