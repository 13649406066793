/* ==========================================================================
   Layout / Block Push Contact
   ========================================================================== */

  .pushContact {
    display: block;
    width: 100%;
    background: $greyLight;
    padding: 55px 0;
    margin-bottom: 50px;

    @include breakpoint(tablet) {
      margin-bottom: 80px;
    }

    &-wrapper {
      @include wrapper();
      text-align: center;
      position: relative;
      z-index: 4;
    }

    &-title {
      @extend .title-section;
      margin-bottom: 30px;
    }

    &-img {
      display: block;
      margin: 10px auto;

      img {
        display: block;
        width: 75px;
        height: auto;
        margin: auto;
        border-radius: 800px;
      }
    }

    &-name {
      font-size: 15px;
    }

    &-btns {
      display: block;
      margin-top: 33px;

      @include breakpoint(phablet) {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .btn {
        margin: auto auto 20px;

        @include breakpoint(phablet) {
          min-width: 220px;
          margin: 0;

          &:first-child {
            margin-right: 20px;
          }
        }

        &:last-child {
          background: $orange;
          color: $whiteColor;
          margin-bottom: 0;

          &:hover {
            background: $blueColor;
            border-color: $blueColor;
          }
        }
      }
    }
  }