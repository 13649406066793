/* ==========================================================================
   Layout / Block Carrefour
   ========================================================================== */

  .blockcarrefour {
    @include wrapper('min');
    margin-bottom: 50px;

    @include breakpoint(tablethor) {
      margin-bottom: 120px;
    }

    .title-special {
      margin-bottom: 35px;

      @include breakpoint(tablethor) {
        margin-bottom: 50px;
      }
    }

    &-blocks {
      display: none;
      position: relative;
      z-index: 4;

      &.slick-initialized {
        display: flex;
      }

      .slick-prev, .slick-next {
        background-color: $whiteColor;
        background-size: 50% auto;
        background-position: 10px center;
        border-radius: 80px;
        padding: 18px;
        top: calc(50% - 20px);

        @include breakpoint(phablet) {
          padding: 20px;
        }

        &.slick-disabled {
          display: none!important;
        }
      }

      .slick-next {
        right: -5px;
      }

      .slick-prev {
        left: -10px;
      }

      .cardcarrefour {
        text-align: center;
        border-radius: 0 30px;
        margin: 0 10px;

        &:nth-child(2n) {
          border-radius: 25px 0;
        }
      }
    }
  }