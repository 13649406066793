/* ==========================================================================
   Page / Accueil
   ========================================================================== */

  .homepage {
    .navigation {
      background: rgba(26, 34, 66, 0.9);
    }

    .header {
      min-height: 90vh;
      margin-bottom: 0;

      @media screen and (max-height: 800px) {
        min-height: 98vh;
      }

      .form-logo {
        content: ' ';
        display: block;
        width: 500px;
        height: 100%;
        min-height: 100vh;
        position: absolute;
        top: 0;
        left: -1px;
        z-index: 3;

        @include breakpoint(smallMobile) {
          width: 135%;
        }

        @include breakpoint(phablet) {
          width: 85%;
        }

        @include breakpoint(tablet) {
          width: 90%;
        }

        @include breakpoint(tablethor) {
          width: 80%;
        }

        @include breakpoint(laptop) {
          width: 900px;
          transform: rotate(0);
          top: -1px;
          left: -1px;
        }
      }

      &-background {
        &:before {
          background: rgba(0, 0, 0, 0.35);
        }

        &:after {
          display: block;
          background: rgba(151, 137, 113, 0.35);
        }
      }

      &-title {
        padding-top: 100px;
        line-height: 1.2;

        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          top: 5em;
        }
      }
    }
  }