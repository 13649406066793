/* ==========================================================================
   Layout / Block Contact
   ========================================================================== */

  .blockcontact {
    @include wrapper('min');
    width: 92%;
    margin-bottom: 50px;
    position: relative;
    z-index: 5;

    @include breakpoint(tablethor) {
      display: flex;
      margin-bottom: 90px;
    }

    &-form {
      display: block;
      box-sizing: border-box;
      padding: 30px 30px 25px;
      flex-basis: 55%;
      background: $greyLight;
      border-radius: 30px 0;
      margin: 0 0 20px;

      @include breakpoint(phablet) {
        margin: 20px 10px;
        padding: 45px 60px 35px;
        border-radius: 50px 0;
      }

      @include breakpoint(tablethor) {
        margin: 10px;
        padding: 40px 50px 35px;
      }

      @include breakpoint(laptop) {
        margin: 20px;
        padding: 45px 60px 35px;
      }

      .wpcf7 {
        height: 100%;

        &-form {
          min-height: 100%;

          .validation-msg {
            padding: 30px 0;

            @include breakpoint(tablethor) {
              height: auto;
              padding: 0;
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              margin: auto;

              .wrapper-msg {
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 100%;
              }
            }
          }
        }
      }
    }

    &-map {
      display: block;
      box-sizing: border-box;
      min-height: 605px;
      padding: 0;
      background: $greyLight;
      border-radius: 0 30px;
      margin: 0;
      position: relative;
      overflow: hidden;

      @include breakpoint(phablet) {
        flex-basis: 45%;
        margin: 20px 10px;
        border-radius: 0 50px;
      }

      @include breakpoint(tablethor) {
        margin: 10px;
      }

      @include breakpoint(laptop) {
        margin: 20px 10px;
      }

      #mapcontact {
        display: block;
        width: 100%;
        height: 100%;
        min-height: 605px;
        position: relative;
        z-index: 1;
      }

      .informations {
        display: block;
        width: calc(100% - 35px);
        box-sizing: border-box;
        padding: 30px 35px;
        background: $whiteColor;
        border-radius: 20px;
        margin: auto;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 25px;
        z-index: 2;

        @include breakpoint(phablet) {
          width: calc(100% - 60px);
          bottom: 40px;
        }

        &-title {
          display: block;
          font-size: 25px;
          font-weight: 800;
          text-align: center;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.2;
          margin-bottom: 20px;
        }

        .text p {
          text-align: center;
          line-height: 1.4;
          margin-bottom: 25px;
        }

        .icon {
          display: block;
          fill: $blueColor;
          width: 30px;
          height: 30px;
          position: absolute;
          top: 5px;
          left: 0;
        }

        .address {
          display: block;
          padding-left: 45px;
          font-size: 16px;
          line-height: 1.4;
          margin-bottom: 20px;
          position: relative;
        }

        .tel {
          display: block;
          padding-left: 45px;
          font-size: 18px;
          line-height: 1.4;
          margin-bottom: 20px;
          position: relative;

          .icon {
            width: 25px;
            height: 25px;
            top: 0;
          }
        }
      }
    }
  }