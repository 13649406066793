/* ==========================================================================
   Layout / Block Team
   ========================================================================== */

  .blockteam {
    display: block;
    width: 100%;
    background: $greyLight;
    border-radius: 100px 0 0 0;
    padding: 45px 0;
    margin-bottom: 40px;

    @include breakpoint(phablet) {
      border-radius: 205px 0 0 0;
      padding: 55px 0;
    }

    @include breakpoint(tablet) {
      margin-bottom: 80px;
    }

    @include breakpoint(tablethor) {
      padding: 90px 0 55px;
      border-radius: 370px 0 0 0;
    }

    @include breakpoint(desktop) {
      margin-bottom: 100px;
      border-radius: 610px 0 0 0;
    }

    &:nth-child(even) {
      border-radius: 0 0 100px 0;

      @include breakpoint(phablet) {
        border-radius: 0 0 205px 0;
      }

      @include breakpoint(tablethor) {
        border-radius: 0 0 370px 0;
      }

      @include breakpoint(desktop) {
        border-radius: 0 0 610px  0;
      }
    }

    &-wrapper {
      display: none;
      width: 85%;
      max-width: 980px;
      margin: auto;
      position: relative;
      z-index: 4;

      &.slick-initialized {
        display: block;
      }

      @include breakpoint(phablet) {
        width: 80%;
      }

      @include breakpoint(tablethor) {
        justify-content: center;
        flex-wrap: wrap;
        width: 75%;
        padding: 0;

        &.slick-initialized {
          display: flex;
        }
      }

      .slick-track {
        display: flex;
      }

      .slick-prev, .slick-next {
        background-color: $greyLight;
        background-size: 50% auto;
        background-position: 10px center;
        border-radius: 80px;
        padding: 20px;
        top: 30%;

        @include breakpoint(phablet) {
          padding: 20px;
        }
      }

      .slick-next {
        right: -10px;
      }

      .slick-prev {
        left: -10px;
      }

      .teamcard {
        margin: 0 10px;
        height: initial!important;

        @include breakpoint(phablet) {
          width: 50%;
        }

        @include breakpoint(tablethor) {
          width: calc(33% - 20px);
        }
      }
    }

    .title-section {
      width: 70%;
      margin: auto;
      margin-bottom: 25px;

      @include breakpoint(phablet) {
        margin-bottom: 35px;
      }

      @include breakpoint(tablethor) {
        margin-bottom: 50px;
      }
    }

    .btn {
      margin: 30px auto 0;

      @include breakpoint(tablethor) {
        margin: 50px auto 0;
      }
    }
  }