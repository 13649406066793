@charset 'UTF-8';

// Base
@import 'base/fonts';
@import 'base/variables';
@import 'base/mixins';
@import 'base/base';
@import 'base/form';

// Components
@import 'components/button';
@import 'components/titles';
@import 'components/menu';
@import 'components/wysiwyg';
@import 'components/testimony';
@import 'components/document';
@import 'components/card';
@import 'components/icons';
@import 'components/cardcarrefour';
@import 'components/productcard';
@import 'components/speciecard';
@import 'components/teamcard';
@import 'components/keycard';
@import 'components/cardaccomp';
@import 'components/modal';
@import 'components/breadcrumb';
@import 'components/pagination';
@import 'components/telecommand';
@import 'components/tarteaucitron';
@import 'components/tarteaucitron-surcharge';
@import 'components/scrollbar';
@import 'components/slick';
@import 'components/slick-theme';

// Layout
@import 'layout/navigation';
@import 'layout/header';
@import 'layout/columns';
@import 'layout/blockpackaging';
@import 'layout/blockadministration';
@import 'layout/blockfield';
@import 'layout/blocktestimonies';
@import 'layout/blockservices';
@import 'layout/blockcommitments';
@import 'layout/blocknews';
@import 'layout/blockdocuments';
@import 'layout/blockkeynumber';
@import 'layout/blockaccompaniment';
@import 'layout/blockaccompanimentsimple';
@import 'layout/blockcarrefour';
@import 'layout/blockpartners';
@import 'layout/blockteam';
@import 'layout/blockrange';
@import 'layout/blocksuggest';
@import 'layout/blocksocialwall';
@import 'layout/blockcontact';
@import 'layout/blockfactories';
@import 'layout/blockworldmap';
@import 'layout/push-contact';
@import 'layout/suggestproducts';
@import 'layout/speciesblock';
@import 'layout/footer';

// Pages
@import 'pages/home';
@import 'pages/search';
@import 'pages/product';
@import 'pages/rangepage';
@import 'pages/news';
@import 'pages/sitemap';
@import 'pages/404';