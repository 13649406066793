/* ==========================================================================
   Layout / Block Terrain
   ========================================================================== */

  .blockfield {
    @include wrapper();
    margin-bottom: 60px;

    @include breakpoint(tablet) {
      margin-bottom: 90px;
    }

    &-title {
      @extend .title-section;
    }

    &-content {
      display: block;
      max-width: 860px;
      border-radius: 30px 0;
      margin: auto;
      position: relative;
      overflow: hidden;

      img {
        display: block;
        width: 100%;
        height: auto;
        transition: all $duration $easeInOutCubic;
      }

      .icon {
        display: block;
        width: 30px;
        height: 30px;
        fill: $whiteColor;
        transition: all $duration $easeInOutCubic;
        position: absolute;
        top: 15px;
        right: 15px;
        z-index: 1;
      }

      .legend {
        display: block;
        width: 100%;
        box-sizing: border-box;
        background: $darkBlue;
        padding: 23px 35px 24px;
        color: $whiteColor;
        position: relative;
        z-index: 1;

        &-title {
          display: block;
          font-family: $font-stack-special;
          font-size: 20px;
          font-weight: 500;
          line-height: 1.35;
          letter-spacing: normal;
          margin-bottom: 4px;
        }

        &-text {
          line-height: 1.24;
        }
      }

      &:hover {
        .icon {
          transform: scale(1.1);
          transition: all $duration $easeInOutCubic;
        }

        img {
          transform: scale(1.05);
          transition: all $duration $easeInOutCubic;
        }
      }
    }
  }