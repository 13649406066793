/* ==========================================================================
   Layout / Block Gamme
   ========================================================================== */

  .blockrange {
    position: relative;

    &:nth-child(even) {
      background: lighten($greyLight, 1%);

      .productcard-img img {
        filter: brightness(1.01);
      }
    }

    &-wrapper {
      @include wrapper('min');
      padding: 45px 0 35px;
      position: relative;
      z-index: 5;

      @include breakpoint(tablet) {
        padding: 60px 0 45px;
      }

      @include breakpoint(tablethor) {
        display: flex;
        align-items: center;
        padding: 90px 0 60px;
      }

      @include breakpoint(laptop) {
        padding: 110px 0 70px;
      }
    }

    &-info {
      display: block;
      box-sizing: border-box;
      margin-bottom: 60px;
      position: relative;
      z-index: 5;

       @include breakpoint(phablet) {
         padding-left: 5%;
         margin-bottom: 35px;
       }

      @include breakpoint(tablethor) {
        flex-basis: 40%;
        width: 40%;
        padding-left: 0;
        padding-right: 3%;
        margin-bottom: 0;
      }

      @include breakpoint(laptop) {
        flex-basis: 43%;
        width: 43%;
      }
    }

    &-title {
      font-family: $font-stack-title;
      font-size: 30px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-transform: uppercase;
    }

    &-subtitle {
      display: block;
      font-family: $font-stack-special;
      max-width: 375px;
      font-size: 20px;
      font-weight: 300;
      line-height: normal;
      letter-spacing: normal;
      margin-bottom: 25px;
    }

    &-text {
      max-width: 394px;

      ul {
        margin-left: 2px;

        li {
          list-style-type: none;
          font-size: 15px;
          font-weight: 600;
          color: $blackColor;
          line-height: 1.47;
          letter-spacing: normal;
          position: relative;
          padding-left: 20px;

          &:not(:last-child) {
            margin-bottom: 10px;
          }

          &:before {
            content: ' ';
            display: block;
            width: 6px;
            height: 6px;
            background: $blueColor;
            border-radius: 800px;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto 0;
          }

          h4 {
            margin: 0;
            padding: 0;
          }
        }
      }
    }

    &-buttons {
      .btn {
        display: block;
        min-width: inherit;
        padding: 12px 35px 14px;
        margin-bottom: 15px;

        @include breakpoint(phablet) {
          display: inline-block;
          margin-right: 15px;
        }
      }
    }

    &-slider {
      @include breakpoint(tablethor) {
        flex-basis: 60%;
        width: 60%;
        max-width: 60%;
      }

      @include breakpoint(laptop) {
        flex-basis: 57%;
        width: 57%;
        max-width: 57%;
      }

      .slick-track {
        display: flex;
      }

      .slick-slide {
        display: inline-block;
        height: auto!important;

        @include breakpoint(phablet) {
          margin: 0 5px;
        }

        @include breakpoint(tablet) {
          margin: 0 10px;
        }
      }

      .slick-dots {
        text-align: right;
        padding-right: 6px;
        box-sizing: border-box;
        margin-top: 5px;
        bottom: 0;

        li {
          margin: 0 5px;
        }

        button {
          width: 16px;
          height: 16px;
          border-color: $blackColor;

          &:before {
            width: 8px;
            height: 8px;
            background: $blackColor;
          }
        }
      }

      .slick-next, .slick-prev {
        width: 26px;
        height: 26px;
        background: url('images/arrow-slide.svg') top center/26px auto no-repeat;
        top: -40px;
        left: auto;
      }

      .slick-next {
        right: 15px;
      }

      .slick-prev {
        right: 58px;
      }
    }
  }