/* ==========================================================================
   Layout / Block Documents
   ========================================================================== */

  .blockdocuments {
    @include wrapper('min');
    margin-bottom: 50px;

    @include breakpoint(tablethor) {
      margin-bottom: 100px;
    }

    .title-special {
      margin-bottom: 35px;

      @include breakpoint(tablethor) {
        margin-bottom: 50px;
      }

      &:before {
        background-image: url('images/titre-forme-orange.svg');
      }
    }

    &-docs {
      @include breakpoint(phablet) {
        display: flex;
        flex-wrap: wrap;
      }

      .document {
        position: relative;
        z-index: 3;

        @include breakpoint(phablet) {
          flex-basis: calc(50% - 10px);
          margin-right: 20px;

          &:nth-child(2n) {
            margin-right: 0;
          }
        }

        @include breakpoint(tablethor) {
          flex-basis: calc(32.5% - 10px);

          &:nth-child(2n) {
            margin-right: 20px;
          }

          &:nth-child(3n) {
            margin-right: 0;
          }
        }

        @include breakpoint(laptop) {
          flex-basis: calc(24.5% - 10px);
          &:nth-child(3n) {
            margin-right: 20px;
          }
          &:nth-child(4n) {
            margin-right: 0;
          }
        }
      }
    }
  }
