/* ==========================================================================
   Layout / Footer
   ========================================================================== */

  .footer {
    display: block;
    background: $darkBlue;
    padding: 35px 0;
    position: relative;
    margin-top: 150px;

    @include breakpoint(tablethor) {
      margin-top: 250px;
    }

    @include breakpoint(mediumdesktop) {
      margin-top: 220px;
    }

    .form-logo {
      display: block;
      width: 370px;
      height: auto;
      position: absolute;
      right: -1px;
      top: -267px;
      z-index: 0;

      @include breakpoint(tablethor) {
        width: 675px;
        top: -486px;
      }

      @include breakpoint(laptop) {
        width: 705px;
        top: -508px;
      }

      @include breakpoint(desktop) {
        width: 755px;
        top: -545px;
      }

      @include breakpoint(mediumdesktop) {
        width: 825px;
        top: -595px;
      }
    }

    &-wrapper {
      @include wrapper('max');
      position: relative;
      z-index: 5;
    }

    &-logo {
      display: block;
      margin-bottom: 25px;

      img {
        display: block;
        width: 250px;
        height: auto;
        max-width: 100%;

        @include breakpoint(tablethor) {
          width: auto;
        }
      }
    }

    &-about {
      display: block;
      width: 90%;
      margin: auto auto 30px;

      @include breakpoint(tablet) {
        display: inline-block;
        width: 50%;
        margin: 0 50px 45px 0;
      }

      @include breakpoint(tablethor) {
        width: 355px;
        margin: 0 125px 30px 0;
      }

      .textwidget {
        display: block;
        font-family: $font-stack-special;
        font-size: 15px;
        font-weight: 300;
        line-height: 1.87;
        letter-spacing: normal;
        color: $whiteColor;
      }
    }

    &-widgets {
      display: block;
      width: 90%;
      margin: auto auto 50px;

      @include breakpoint(tablet) {
        display: inline-block;
        width: calc(50% - 65px);
        vertical-align: bottom;
        margin-bottom: 65px;
      }

      @include breakpoint(tablethor) {
        width: auto;
        margin-bottom: 80px;
      }

      .searchbox {
        max-width: 320px;
        position: relative;
        margin-bottom: 35px;

        input {
          display: table;
          background: $whiteColor;
          border: 0;
          font-size: 15px;
          width: 100%;
          box-sizing: border-box;
          font-family: $font-stack-common;
          font-weight: 500;
          line-height: normal;
          color: $greyText;
          padding: 12px 25px 14px;
          border-radius: 22px;

          @include breakpoint(tablethor) {
            width: 320px;
          }
        }

        .search-submit {
          display: block;
          position: absolute;
          top: 10px;
          right: 20px;

          .icon {
            display: block;
            width: 21px;
            height: 22px;
            fill: $greyText;
            transition: all $duration $easeInOutCirc;
          }

          &:hover {
            .icon {
              fill: $orange;
              transition: all $duration $easeInOutCirc;
            }
          }
        }
      }
    }

    &-social {
      display: inline-block;

      span {
        display: block;
        font-size: 20px;
        font-weight: 500;
        line-height: 1.35;
        font-family: $font-stack-special;
        letter-spacing: normal;
        color: $whiteColor;
        margin-bottom: 15px;
      }

      .social {
        li {
          display: block;
          float: left;
          list-style-type: none;

          &:first-child {
            margin-right: 35px;
          }

          .icon {
            fill: $whiteColor;
            width: 30px;
            height: 30px;
            transition: all $duration $easeOutCubic;

            &-youtube {
              width: 38px;
              position: relative;
              top: 2px;
            }
          }

          a:hover .icon {
            fill: $orange;
            transition: all $duration $easeOutCubic;
          }
        }
      }
    }

    &-partner {
      display: inline-block;
      vertical-align: top;
      float: right;
    }

    &-links {
      display: block;
      text-align: center;

      ul li {
        display: inline-block;
        list-style-type: none;

        &:not(:last-child) {
          &:after {
            content: '-';
            color: $whiteColor;
            text-decoration: none;
            font-family: $font-stack-special;
            font-size: 17px;
            font-weight: 700;
            margin: 0 10px;
          }
        }
      }

      a {
        display: inline-block;
        color: $whiteColor;
        text-decoration: none;
        font-family: $font-stack-special;
        font-size: 17px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }