/* ==========================================================================
   Layout / Block Social Wall
   ========================================================================== */

  .blocksocialwall {
    @include wrapper('min');
    display: none;
    margin-bottom: 60px;
    position: relative;
    z-index: 1;

    &.slick-initialized {
      display: block;
    }

    @include breakpoint(phablet) {
      display: flex;
      flex-wrap: wrap;
    }

    @include breakpoint(tablethor) {
      margin-bottom: 130px;
    }

    &-title {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: $font-stack-title;
      font-size: 50px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.8;
      letter-spacing: normal;
      position: relative;
      padding-top: 17px;

      @include breakpoint(phablet) {
        flex-basis: 50%;
      }

      @include breakpoint(tablethor) {
        flex-basis: 33%;
      }

      @include breakpoint(laptop) {
        flex-basis: 50%;
      }

      .icon {
        display: block;
        fill: $greyLight;
        width: 235px;
        height: 235px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;

        @include breakpoint(laptop) {
          top: 3%;
          left: 18%;
        }
      }

      span {
        padding: 128px 0 40px 112px;
        position: relative;
        z-index: 1;

        @include breakpoint(phablet) {
          padding: 0;
        }

        @include breakpoint(tablet) {
          padding: 15px 0 0 85px;
        }

        @include breakpoint(tablethor) {
          padding: 30px 0 0 110px;
        }

        @include breakpoint(laptop) {
          padding: 30px 0 0 10px;
        }
      }
    }

    .social {
      display: inline-block;
      border-radius: 30px 0;
      background: $greyLight;
      margin: 0 10px 20px;

      @include breakpoint(phablet) {
        display: block;
        margin: 0 10px 25px;
      }

      @include breakpoint(phablet) {
        flex-basis: calc(50% - 20px);
      }

      @include breakpoint(tablethor) {
        flex-basis: calc(33% - 20px);
      }

      @include breakpoint(laptop) {
        flex-basis: calc(25% - 20px);
      }

      &-img {
        position: relative;
        overflow: hidden;
        border-radius: 20px 20px 0 0;
        max-height: 155px;

        @include breakpoint(phablet) {
          max-height: 168px;
        }

        @include breakpoint(tablethor) {
          max-height: 155px;
        }

        @include breakpoint(laptop) {
          max-height: 140px;
        }

        @include breakpoint(desktop) {
          max-height: 168px;
        }

        img {
          display: block;
          border: none;
          width: 100%;
          height: auto;
          pointer-events: none;
          filter: sepia(1) grayscale(30%);
          position: relative;
          top: -28px;
          z-index: 0;

          @include breakpoint(phablet) {
            top: 0;
          }

          @include breakpoint(tablet) {
            top: -30px;
          }

          @include breakpoint(laptop) {
            top: -24px;
          }

          @include breakpoint(1440px) {
            top: -1.6em;
          }
        }

        .icon {
          display: block;
          width: 58px;
          height: 56px;
          fill: $blueColor;
          position: absolute;
          margin: auto;
          transition: all $duration $easeInOutQuart;
          top: calc(50% - 28px);
          left: 0;
          right: 0;
          z-index: 1;
        }
      }

      &-content {
        display: block;
        box-sizing: border-box;
        padding: 15px 35px 55px;
        font-size: 17px;
        font-weight: 600;
        line-height: 1.4;
        letter-spacing: normal;
      }

      &.youtube{
        .icon {
            fill: $youtube;
        }

        &:hover .icon {
          fill: $orange;
          transition: all $duration $easeInOutQuart;
        }
      }

      &:last-child {
        @include breakpoint(phablet) {
          display: none;
        }

        @include breakpoint(laptop) {
          display: block;
        }
      }
    }

    .slick-prev, .slick-next {
      background-color: $whiteColor;
      background-size: 50% auto;
      background-position: 10px center;
      border-radius: 80px;
      padding: 18px;
      top: 22em;

      @include breakpoint(phablet) {
        padding: 20px;
      }

      &.slick-disabled {
        display: none!important;
      }
    }

    .slick-next {
      right: -8px;
    }

    .slick-prev {
      left: -8px;
    }

    .player {
      position: relative;
      overflow: hidden;
      border-radius: 20px 20px 0 0;
      height: 155px;
      cursor: pointer;

      @include breakpoint(phablet) {
        height: 168px;
      }

      @include breakpoint(tablethor) {
        height: 155px;
      }

      @include breakpoint(laptop) {
        height: 140px;
      }

      @include breakpoint(desktop) {
        height: 168px;
      }

      .youtube_player {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        border-radius: 0;
        transition: top .4s ease-in-out;
        z-index: 10;

        iframe {
          max-width: 100%;
          max-height: 100%;
        }
      }

      &.active {
        .youtube_player {
          top: 0;
        }
      }
    }
  }