/* ==========================================================================
   Components / Breadcrumb
   ========================================================================== */

  .breadcrumb {
    display: block;
    padding: 15px 0;
    margin-bottom: 15px;

    @include breakpoint(laptop) {
      margin-left: 11em;
      margin-bottom: 30px;
    }

    @include breakpoint(mediumdesktop) {
      margin-left: 8.5em;
    }

    @include breakpoint(largedesktop) {
      margin-left: 0;
    }

    ul {
      line-height: 1.2;
    }

    ul li {
      display: inline-block;
      list-style-type: none;
      font-family: $font-stack-title;
      font-size: 14px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: normal;
      line-height: 1.4;
      color: $greyText;

      @include breakpoint(tablet) {
        margin-bottom: 5px;
      }

      &:last-child {
        font-weight: 900;
        color: $orange;
      }

      &:not(:last-child):after {
        content: ' ';
        display: inline-block;
        vertical-align: top;
        width: 10px;
        height: 10px;
        background: url('images/arrow-orange.svg') center center/100% auto no-repeat;
        margin: 1px 5px 0;
        position: relative;
        top: 3px;

        @include breakpoint(tablethor) {
          margin: 1px 3px 0 10px;
        }
      }

      a {
        display: inline-block;
        vertical-align: top;
        max-width: calc(100% - 20px);
        font-size: 14px;
        font-weight: 400;
        position: relative;
        line-height: 1.3;
        transition: all $duration $easeInOutExpo;
        padding-bottom: 1px;

        &:after {
          content: ' ';
          display: block;
          background: $blueColor;
          width: 0;
          height: 1px;
          transition: all $duration $easeInOutExpo;
          position: absolute;
          bottom: 0;
          left: 0;
        }

        &:hover {
          color: $blueColor;
          transition: all $duration $easeInOutExpo;

          &:after {
            width: 100%;
            transition: all $duration $easeInOutExpo;
          }
        }
      }
    }
  }