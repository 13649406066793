/* ==========================================================================
   Components / Title
   ========================================================================== */

  .h1-title {
    display: block;
    font-size: 35px;
    font-weight: 900;
    font-family: $font-stack-title;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: 1.49px;

    @include breakpoint(phablet) {
      font-size: 42px;
      line-height: 1.43;
    }
  }

  .h2-title {
    display: block;
    font-size: 32px;
    font-family: $font-stack-title;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    margin-bottom: 15px;
  }

  .h3-title {
    display: block;
    font-size: 24px;
    font-weight: 600;
    font-family: $font-stack-title;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    color: $blueColor;
  }

  .h4-title {
    display: block;
    font-family: $font-stack-title;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.3;
    letter-spacing: normal;
    color: $greyText;
    margin-bottom: 20px;
  }

  .h5-title {

  }

  /* SPECIAL TITLES */

  .title-special {
    display: block;
    font-family: $font-stack-title;
    font-size: 28px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: normal;
    padding: 25px 5px 0 35px;
    position:relative;
    margin-bottom: 20px;

    @include breakpoint(phablet) {
      font-size: 30px;
      line-height: 1.33;
      margin-bottom: 35px;
      padding: 43px 50px 0;
    }

    &:before {
      content: ' ';
      display: block;
      width: 60px;
      height: 60px;
      background: url('images/titre-forme-bleue.svg') center center/100% auto no-repeat;
      position: absolute;
      top: 0;
      left: 0;

      @include breakpoint(phablet) {
        width: 87px;
        height: 87px;
      }
    }
  }

  .title-special-right {
    @extend .title-special;
    margin-left: auto;
    text-align: right;
    padding: 25px 35px 0 5px;

    @include breakpoint(phablet) {
      padding: 42px 48px 0;
    }

    &:before {
      right: 0;
      left: auto;
      transform: rotate(90deg);
    }
  }

  .title-section {
    display: block;
    font-family: $font-stack-title;
    color: $greyText;
    font-size: 30px;
    font-weight: 600;
    line-height: 1.15;
    letter-spacing: normal;
    text-align: center;
    margin-bottom: 35px;

    @include breakpoint(phablet) {
      margin-bottom: 45px;
    }

    @include breakpoint(tablethor) {
      margin-bottom: 60px;
    }
  }