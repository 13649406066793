/* ==========================================================================
   Components / Testimony
   ========================================================================== */

  .testimony {
    display: block;
    padding-left: 0;
    padding-top: 57px;
    margin-left: 10px;
    box-sizing: border-box;
    background: url('images/icone-quote.svg') top left/135px auto no-repeat;

    @include breakpoint(tablet) {
      padding-right: 50px;
      padding-left: 60px;
    }

    @include breakpoint(tablethor) {
      padding-left: 95px;
      padding-right: 0;
    }

    &-title {
      display: block;
      font-family: $font-stack-title;
      font-size: 20px;
      font-weight: 600;
      line-height: 1.35;
      letter-spacing: normal;
      margin-bottom: 23px;
    }

    &-text {
      margin-bottom: 30px;

      p, span {
        font-weight: 300!important;
      }
    }

    &-identity {
      font-family: $font-stack-special;
      font-size: 18px;
      font-weight: 600;
      line-height: 1;
      letter-spacing: normal;
      text-transform: uppercase;

      span {
        display: block;
        font-family: $font-stack-special;
        font-size: 13px;
        margin-top: 3px;
        font-weight: 400;
        text-transform: none;
        line-height: normal;
      }
    }
  }