/* ==========================================================================
   Layout / Block Columns
   ========================================================================== */

  .wp-block-columns {
    @include wrapper('min');
    display: block!important;
    margin: 20px auto 50px!important;

    @include breakpoint(tablethor) {
      display: flex!important;
      align-items: center;
      margin: 90px auto 120px!important;
    }

    &.reverse-order-mobile {
      display: flex!important;
      flex-direction: column-reverse;

      @include breakpoint(tablethor) {
        flex-direction: row;
      }
    }
  }

  .wp-block-column {
    .blockwysiwyg {
      width: 100%;
      margin-bottom: 0;

      @include breakpoint(tablethor) {
        width: 80%;
      }
    }

    &:last-child {
      .blockwysiwyg {
        @include breakpoint(tablethor) {
          margin-left: auto;
          margin-right: 10%;
        }

        @include breakpoint(laptop) {
          margin-right: 5%;
        }
      }
    }

    .wp-block-image {
      width: 100%;
      max-width: inherit;
      margin: 0!important;

      figure {
        width: 100%;
      }
    }
  }