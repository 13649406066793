/* ==========================================================================
   Page / Page Résultats Recherche
   ========================================================================== */

  .search {
    &-header {
      .breadcrumb {
        @include breakpoint(laptop) {
          margin-bottom: 45px;
        }
      }

      .header-wrapper {
        min-height: initial;

        @include breakpoint(laptop) {
          padding-bottom: 115px;
        }

        @include breakpoint(mediumdesktop) {
          padding-left: 50px;
        }
      }

      .header-background {
        background-image: url('images/visuel-bovins-repro.jpg');
      }
    }

    .results {
      @include wrapper('min');
      margin-top: 50px;
      margin-bottom: 50px;
      position: relative;
      z-index: 5;

      @include breakpoint(laptop) {
        margin-bottom: 100px;
      }

      &-elem {
        display: block;
        width: 100%;
        padding-bottom: 20px;
        margin-bottom: 15px;
        border-bottom: 1px solid darken($greyLight, 10%);

        @include breakpoint(phablet) {
          display: flex;
          padding-bottom: 5px;
          margin-bottom: 25px;
        }
      }

      &-image {
        display: block;
        width: 100%;
        margin-bottom: 20px;
        overflow: hidden;

        @include breakpoint(phablet) {
          width: auto;
          flex-basis: 200px;
          margin-right: 25px;
        }

        @include breakpoint(tablethor) {
          flex-basis: 255px;
        }

        img {
          display: block;
          width: 100%;
          height: auto;
          border-radius: 30px 0;
          transition: all $durationIntermediate $easeInOutBack;
        }
      }

      &-text {
        display: block;
        text-align: left;
        padding: 0 5px;

        @include breakpoint(phablet) {
          padding: 0 10px;
          flex-basis: calc(100% - 230px);
          width: calc(100% - 265px);
        }

        @include breakpoint(tablethor) {
          flex-basis: calc(100% - 315px);
          width: calc(100% - 350px);
        }
      }

      &-infos {
        margin-bottom: 15px;

        ul {
          margin: 0;
        }

        li {
          display: inline-block;
          vertical-align: top;
          list-style-type: none;
          font-size: 11px;
          font-family: $font-stack-title;
          font-weight: 600;
          line-height: normal;
          letter-spacing: normal;
          text-transform: uppercase;
          color: $whiteColor;
          background: $blueColor;
          padding: 8px 14px;
          border-radius: 5px 0 5px 0;
          margin-right: 6px;
        }
      }

      &-title {
        margin-bottom: 12px;

        a {
          transition: all $duration $easeInOutCirc;
        }

        a .h4-title:hover {
          color: $blueColor;
          transition: all $duration $easeInOutCirc;
        }

        .h4-title {
          font-size: 22px;
          margin-bottom: 10px;
        }
      }

      &-excerpt {
        font-family: $font-stack-special;
        font-size: 16px;
        line-height: 1.5;
        margin-bottom: 20px;
      }
    }
  }