/* ==========================================================================
   Components / Card Accompagniement
   ========================================================================== */

  .cardaccomp {
    display: block;
    //display: flex;
    //flex-direction: column;
    border-radius: 30px 0;
    background: $greyLight;
    overflow: hidden;

    &-img {
      display: block;
      position: relative;

      &:before {
        content: ' ';
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }

      img {
        display: block;
        width: 100%;
        height: auto;
        position: relative;
        z-index: 0;
      }

      strong {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        text-align: center;
        font-family: $font-stack-title;
        font-size: 23px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.39;
        letter-spacing: normal;
        color: $whiteColor;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
      }
    }

    &-text {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 100%;
      //height: 100%;
      padding: 25px 25px 45px;

      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        height: auto;
      }

      @include breakpoint(laptop) {
        padding: 25px 35px 45px;
      }

      .h4-title {
        max-width: 100%;
        font-weight: 500;
        margin-bottom: 15px;
        flex-grow: 1;
      }

      p {
        max-width: 100%;

        &:not(:last-child) {
          margin-bottom: 30px;
        }
      }

      .text {
        max-width: 100%;
        flex-grow: 1;
      }

      .text + .btn {
        margin-top: 25px;
      }

      ul {
        display: block;
        max-width: 100%;
        margin-left: 15px;
        margin-bottom: 15px;

        @include breakpoint(laptop) {
          margin-left: 30px;
        }

        li {
          display: block;
          list-style-type: none;
          box-sizing: border-box;
          font-size: 15px;
          font-weight: 900;
          line-height: normal;
          font-family: $font-stack-special;
          color: $blackColor;
          padding-left: 35px;
          margin-bottom: 20px;
          position: relative;

          @include breakpoint(phablet) {
            flex-basis: 50%;
            margin-bottom: 15px;
          }

          @include breakpoint(tablet) {
            margin-bottom: 30px;
          }

          &:before {
            content: ' ';
            display: block;
            width: 16px;
            height: 16px;
            background: url('images/icone-check.svg') center center/16px 16px no-repeat;
            position: absolute;
            top: 1px;
            left: 0;
          }
        }
      }

      .btn {
        margin: auto;
        min-width: 190px;
      }
    }
  }