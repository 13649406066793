/* ==========================================================================
   Components / Card Carrefour
   ========================================================================== */

  .cardcarrefour {
    display: block;
    position: relative;
    border-radius: 25px 0;
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.35;
      background: $blackColor;
      transition: all .2s ease-in-out;
    }

    &-img {
      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    &-text {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      flex-flow: column;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      color: $whiteColor;
      font-weight: 500;
      font-family: $font-stack-title;

      .picto {
        display: block;
        width: 60px;
        height: auto;
        margin: 0 0 10px;
      }

      .title {
        display: block;
        width: 100%;
      }

      .icon {
        fill: #FFF;
        width: 0;
        height: 0;
        transition: all .2s ease-in-out;
      }
    }
  }

  a.cardcarrefour:hover, a.cardcarrefour:focus {
    &:before {
      opacity: 0.6;
      background: $blueColor;
      transition: all .2s ease-in-out;
    }
    .icon {
      width: 20px;
      height: 20px;
      margin-top: 5px;
    }
  }