/* ==========================================================================
   Layout / Block accompagnement Simple
   ========================================================================== */

  .accompanimentsimple {
    @include wrapper('min');
    margin-bottom: 50px;

    @include breakpoint(tablethor) {
      margin-bottom: 100px;
    }

    .title-special {
      margin-bottom: 35px;

      @include breakpoint(tablethor) {
        margin-bottom: 50px;
      }
    }

    &-blocks {
      display: block;

      @include breakpoint(phablet) {
        display: flex;
        flex-wrap: wrap;
      }

      .cardaccomp {
        text-align: center;
        border-radius: 0 30px;
        margin-bottom: 25px;

        @include breakpoint(phablet) {
          flex-basis: 47.4%;
          margin-right: 20px;

          &:nth-child(2n) {
            margin-right: 0;
          }
        }

        @include breakpoint(tablet) {
          flex-basis: 48.4%;

          &:nth-child(2n) {
            margin-right: 0;
          }
        }

        @include breakpoint(tablethor) {
          flex-basis: 31.8%;

          &:nth-child(2n) {
            margin-right: 20px;
          }

          &:nth-child(3n) {
            margin-right: 0;
          }
        }

        @include breakpoint(laptop) {
          flex-basis: 23.5%;

          &:nth-child(3n) {
            margin-right: 20px;
          }

          &:nth-child(4n) {
            margin-right: 0;
          }

          @include breakpoint(mediumdesktop) {
            flex-basis: 23.8%;
          }
        }

        &-text {
          padding: 35px 25px 45px;

          @include breakpoint(laptop) {
            padding: 40px 35px 45px;
          }
        }
      }
    }
  }