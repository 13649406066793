/* ==========================================================================
   Page / 404
   ========================================================================== */


  .error404 {
    .header {
      margin-bottom: 0;

      &-wrapper {
        @include breakpoint(laptop) {
          min-height: 485px;
        }
      }
    }

    .footer {
      margin-top: 0;
    }
  }