/* ==========================================================================
   Components / Specie Card
   ========================================================================== */

  .speciecard {
    display: block;
    background: $greyLight;
    border-radius: 30px 0;
    overflow: hidden;

    @include breakpoint(phablet) {
      box-shadow: 0 0 25px 0 rgba(0,0,0,0.05);
    }

    &-img {
      display: block;
      position: relative;

      &:before {
        content: ' ';
        display: block;
        width: 100%;
        height: 100%;
        background: $orange;
        opacity: 0;
        transition: all $duration $easeInOutSine;
        position: absolute;
        top: 0;
        left: 0;
      }

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    &-name {
      display: block;
      box-sizing: border-box;
      text-align: center;
      font-size: 16px;
      font-weight: bold;
      line-height: normal;
      padding: 25px 50px 50px;
      transition: all $durationSmall $easeInOutExpo;
    }

    &:hover {
      .speciecard-img:before {
        opacity: 0.1;
        transition: all $durationSmall $easeInOutExpo;
      }

      .speciecard-name {
        color: $orange;
        transition: all $durationSmall $easeInOutExpo;
      }
    }
  }