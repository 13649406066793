/* ==========================================================================
   Components / MIXINS
   ========================================================================== */

  /*********************
    BREAKPOINTS RESPONSIVE LAYOUT
    *********************/

  @mixin breakpoint($point) {
    @if $point == largedesktop {
      @media (min-width: 1800px) { @content ; }
    }@else if $point == mediumdesktop {
      @media (min-width: 1500px) { @content ; }
    }@else if $point == desktop {
      @media (min-width: 1360px) { @content ; }
    }@else if $point == laptop {
      @media (min-width: 1200px) { @content ; }
    }@else if $point == tablethor {
      @media (min-width: 1000px) { @content ; }
    }@else if $point == tablet {
      @media (min-width: 760px) { @content ; }
    }@else if $point == phablet {
      @media (min-width: 576px)  { @content ; }
    }@else if $point == mobileonly {
      @media (max-width: 576px)  { @content ; }
    }@else if $point == smallMobile {
      @media (max-width: 325px)  { @content ; }
    }@else {
      @media (min-width: $point)  { @content ; }
    }
  }

    // Wrapper
  @mixin wrapper($type: 'max') {
    display: block;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;

    @include breakpoint(tablethor) {
      width: 90%;
    }

    @if $type == 'max' {
      max-width: $wrapperMax;
    }@else {
      max-width: $wrapperMin;
    }
  }

  // Transition
  @mixin transition($transition...) {
    transition: $transition;
  }
  @mixin transition-property($property...) {
    transition-property: $property;
  }
  @mixin transition-duration($duration...) {
    transition-property: $duration;
  }
  @mixin transition-timing-function($timing...) {
    transition-timing-function: $timing;
  }

  // Flex Row
  @mixin flex-row {
    display: flex;
    flex-direction: row;
  }

  // Flex Column
  @mixin flex-row {
    display: flex;
    flex-direction: column;
  }


