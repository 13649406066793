/* ==========================================================================
   Components / Modal
   ========================================================================== */

  .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    background: rgba(190, 190, 190, 0.9);
    transition: all $durationSmall $easeInOutSine;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 15;

    &.show {
      visibility: visible;
      opacity: 1;
      transition: all $durationSmall $easeInOutSine;

      .modal-content {
        opacity: 1;
        transform: scale(1);
        transition: all $duration $easeInOutBack;
      }
    }

    &-content {
      display: block;
      width: 90%;
      transform: scale(0.5);
      max-height: 90%;
      max-width: 630px;
      box-sizing: border-box;
      background: $whiteColor;
      padding: 35px 35px 30px;
      border-radius: 50px 0;
      opacity: 0;
      transition: all $duration $easeInOutBack;
      position: relative;
      overflow: auto;

      @include breakpoint(phablet) {
        padding: 50px 55px 30px;
      }
    }

    &-form {
      .intro {
        h3 {
           @extend .h2-title;
        }

        p {
          width: 90%;
          margin-bottom: 25px;
        }
      }
    }

    &-close {
      display: block;
      position: absolute;
      top: 25px;
      right: 30px;

      &:hover {
        &:before {
          color: $blueColor;
          transition: all $duration $easeInOutSine;
        }
      }

      &:before {
        content: 'X';
        color: $orange;
        font-weight: 400;
        font-size: 25px;
        font-family: $font-stack-title;
        transition: all $duration $easeInOutSine;
      }

      .text {
        display: none;
      }
    }
  }