@charset "UTF-8";

/* Slider */

.slick-slider {
    display: none;

    &.slick-initialized {
        display: block;
    }
}

.slick-list {
    .slick-loading & {
        opacity: 0;
        display: none;
    }
}

/* Arrows */

.slick-prev, .slick-next {
    display: block;
    width: 23px;
    height: 23px;
    background: url('images/arrow-orange.svg') center center/100% auto no-repeat;
    transition: all $duration $easeInOutBack;
    position: absolute;
    text-indent: -999999px;
    top: calc(50% - 13px);
    padding: 0;
    border: none;
    outline: none;
    z-index: 2;
    cursor: pointer;

    &.slick-disabled {
        opacity: 0.2;
        transition: all $duration $easeInOutBack;
        cursor: default;
    }
}

.slick-prev {
    left: 25px;
    transform: rotate(180deg);

    @include breakpoint(tablet) {
        left: 40px;
    }

    &:not(.slick-disabled):hover {
        transform: rotate(180deg) translateX(3px);
        transition: all $duration $easeInOutBack;
    }
}

.slick-next {
    right: 25px;

    @include breakpoint(tablet) {
        right: 40px;
    }

    &:not(.slick-disabled):hover {
        transform: translateX(3px);
        transition: all $duration $easeInOutBack;
    }
}

/* Dots */

.slick-dots {
    display: block;
    width: 100%;
    list-style: none;
    text-align: center;
    padding: 0;
    margin: 0;
    position: relative;
    bottom: 10px;

    li {
        display: inline-block;
        height: 15px;
        width: 15px;
        margin: 0 8px;
        padding: 0;
        position: relative;
        cursor: pointer;

        &.slick-active button:before {
            opacity: 1;
            transform: scale(1);
            transition: all $duration $easeInOutBack;
        }
    }

    button {
        background: transparent;
        display: block;
        height: 15px;
        width: 15px;
        outline: 0;
        padding: 0;
        line-height: 0;
        font-size: 0;
        color: transparent;
        border: 1px solid #cbcbcb;
        border-radius: 50px;
        cursor: pointer;

        &:before {
            content: ' ';
            display: block;
            position: absolute;
            top: 4px;
            left: 4px;
            width: 7px;
            height: 7px;
            line-height: 0;
            text-align: center;
            border-radius: 50px;
            background: $orange;
            opacity: 0;
            transform: scale(0);
            transition: all $duration $easeInOutBack;
        }
    }
}