/* ==========================================================================
   Components / Bandeau des cookies
   ========================================================================== */

  #tarteaucitronRoot * {
    font-family: $font-stack-title;
    box-sizing: initial;
    color: #333;
    font-size: 14px;
    line-height: normal;
    vertical-align: initial;
  }

  // Bandeau principal
  #tarteaucitronAlertBig {
    display: block;
    width: 100%;
    background: $greyText;
    color: $whiteColor;
    font-family: $font-stack-title;
    text-align: center;
    font-size: 18px;
    line-height: 1;
    padding: 25px 30px 15px;
    margin: auto;
    left: 0;
    position: fixed;
    box-sizing: border-box;
    z-index: 2147483645;

    @include breakpoint(laptop) {
      padding: 25px 100px 5px;
    }

    // Texte du bandeau
    #tarteaucitronDisclaimerAlert {
      display: block;

      p {
        display: block;
        color: $whiteColor;
        font-family: $font-stack-title;
        font-size: 15px;
        font-weight: 300;
        letter-spacing: 0.01rem;
        line-height: 1.3;
        text-align: left;

        @include breakpoint(phablet) {
          text-align: center;
        }

        @include breakpoint(laptop) {
          line-height: 1.5;
        }

        strong {
          font-size: 13px;
          line-height: 1;
        }
      }
    }

    // Boutons du bandeau

    #tarteaucitronPersonalize {
      display: inline-block;
      padding: 8px 15px;
      background: $whiteColor;
      font-family: $font-stack-title;
      color: $greyText;
      font-weight: 500;
      border-radius: 800px;
      min-width: inherit;
      text-align: center;
      margin: 10px;
      transition: all $duration $easeInOutCirc;

      &:hover {
        background: $greenColor;
        color: $whiteColor;
      }
    }

    #tarteaucitronCloseAlert {
      display: inline-block;
      color: $whiteColor;
      font-size: 14px;
      font-family: $font-stack-title;
      font-weight: 600;
      text-decoration: underline;
      letter-spacing: 0.01rem;
      line-height: 1;
      padding: 0 2px;
    }
  }

  .tarteaucitronAlertBigTop {
    top: 0;
  }

  .tarteaucitronAlertBigBottom {
    bottom: 0;
  }

  // Barre qui défile au fur et à mesure du scroll
  #tarteaucitronPercentage {
    display: block;
    position: fixed;
    background: $greenColor;
    height: 4px;
    bottom: 50px;
  }

  #tarteaucitronAlertSmall {
    display: none!important;
  }

  .modal-open{
    overflow: hidden;
    height: 100%;
  }

  #tarteaucitron a {
    color: rgb(66, 66, 66);
    font-size: 11px;
    font-weight: 700;
    text-decoration: none;
  }

  /***
  * Root div added just before </body>
  */
  #tarteaucitronRoot {
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }

  #tarteaucitronRoot .tarteaucitronH1 {
    font-size: 2em;
    text-align: center;
    color: #fff;
    font-weight: 500;
    font-family: $font-stack-common;
    margin: 15px 0 28px;
  }

  #tarteaucitronRoot .tarteaucitronH2 {
    display: inline-block;
    margin: 12px 0 0 10px;
    color: #fff;
  }

  #tarteaucitronCookiesNumberBis.tarteaucitronH2 {
    margin-left: 0;
  }


/********************************************************
********** Control panel*********************************
********************************************************/

    #tarteaucitronRoot {
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
    }

    #tarteaucitronRoot div#tarteaucitron {
      left: 0;
      right: 0;
      margin: auto;
    }

    #tarteaucitron .clear {
      clear: both;
    }

    #tarteaucitronRoot button#tarteaucitronBack {
      background: #eee;
    }

    #tarteaucitron {
      @media screen and (min-width:768px) and (max-width:991px) {
        border: 0 !important;
        left: 0 !important;
        margin: 0 5% !important;
        max-height: 80% !important;
        width: 90% !important;
      }
    }

    #tarteaucitronRoot button {
      background: transparent;
      border: 0;
    }

    #tarteaucitronAlertBig strong, #tarteaucitronAlertSmall strong,
    #tarteaucitronAlertBig a, #tarteaucitronAlertSmall a {
      color: #fff;
    }

    #tarteaucitron strong {
      font-size: 22px;
      font-weight: 500;
    }

    #tarteaucitron ul {
      padding: 0;
    }

  // Petit bandeau en bas à droite
    #tarteaucitronManager {
      display: none;
      font-family: $font-stack-title;
      color: $whiteColor;
      font-weight: 600;
      font-size: 10px;
      background: $greenColor!important;
      padding: 12px 15px 12px 25px;
      text-align: center;
      border-radius: 30px 0 0 0;
      transition: all $duration $easeInOutCirc;
      position: fixed;
      right: 0;
      bottom: 0;
      z-index: 5;

      @include breakpoint(tablet) {
        display: block;
      }

      &:hover {
        background: $orange!important;
        transition: all $duration $easeInOutCirc;
      }
    }

    #tarteaucitron .tarteaucitronH3 {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 5px;
    }

    #tarteaucitron .tarteaucitronH1, #tarteaucitron .tarteaucitronH2, #tarteaucitron .tarteaucitronH3, #tarteaucitron .tarteaucitronH4, #tarteaucitron .tarteaucitronH5, #tarteaucitron .tarteaucitronH6 {
      display: block;
    }

    .cookie-list {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    #tarteaucitronServices {
      margin-top: 21px;
      box-shadow: 0 0 35px #575757;

      .tarteaucitronMainLine {
        background: #333;
        border: 3px solid #333;
        border-left: 9px solid #333;
        border-top: 5px solid #333;
        margin-bottom: 0;
        margin-top: 21px;
        position: relative;
      }
    }

    #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronClosePanelCookie, #tarteaucitron #tarteaucitronClosePanel {
      background: #333333;
      color: #fff;
      cursor: pointer;
      font-size: 12px;
      font-weight: 700;
      text-decoration: none;
      padding: 4px 0;
      position: absolute;
      right: 0;
      text-align: center;
      width: 70px;
    }

    #tarteaucitronServices::-webkit-scrollbar {
      width: 5px;
    }

    #tarteaucitronServices::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 0 rgba(0,0,0,0);
    }

    #tarteaucitronServices::-webkit-scrollbar-thumb {
      background-color: #ddd;
      outline: 0px solid slategrey;
    }

    #tarteaucitronBack {
      background: #fff;
      display: none;
      height: 100%;
      left: 0;
      opacity: 0.7;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 2147483646;
    }

    #tarteaucitron {
      display: none;
      max-height: 80%;
      left: 50%;
      margin: 0 auto 0 -430px;
      padding: 0;
      position: fixed;
      top: 6%;
      width: 860px;
      z-index: 2147483647;
    }

    #tarteaucitron .tarteaucitronBorder {
      background: #fff;
      border: 2px solid #333;
      border-top: 0;
      height: auto;
      overflow: auto;
    }

    #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronClosePanelCookie,
    #tarteaucitron #tarteaucitronClosePanel {
      background: #333333;
      color: #fff;
      cursor: pointer;
      font-size: 12px;
      font-weight: 700;
      text-decoration: none;
      padding: 4px 0;
      position: absolute;
      right: 0;
      text-align: center;
      width: 70px;
    }

    #tarteaucitron #tarteaucitronDisclaimer {
      color: #555;
      font-size: 12px;
      margin: 15px auto 0;
      width: 80%;
    }

    .tarteaucitronSelfLink, #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronHidden,
    #tarteaucitron #tarteaucitronServices .tarteaucitronHidden {
      background: rgba(51, 51, 51, 0.07);
    }

    a.tarteaucitronSelfLink {
       text-align: center!important;
       display: block;
       padding: 7px!important;
     }

    #tarteaucitron #tarteaucitronServices .tarteaucitronHidden {
      display: none;
      position: relative;
    }

    #tarteaucitronCookiesList .tarteaucitronH3.tarteaucitronTitle {
      width: 100%;
      box-sizing: border-box;
    }

    #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronTitle,
    #tarteaucitron #tarteaucitronServices .tarteaucitronTitle button,
    #tarteaucitron #tarteaucitronInfo,
    #tarteaucitron #tarteaucitronServices .tarteaucitronDetails {
      color: #fff;
      display: inline-block;
      font-size: 14px;
      font-weight: 700;
      margin: 20px 0px 0px;
      padding: 5px 20px;
      text-align: left;
      width: auto;
      background: #333;
    }

    #tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName a,
    #tarteaucitron #tarteaucitronServices .tarteaucitronTitle a {
      color: #fff;
      font-weight: 500;
    }

    #tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName a:hover,
    #tarteaucitron #tarteaucitronServices .tarteaucitronTitle a:hover {
      text-decoration: none !important;
    }

    #tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName a {
      font-size: 22px;
    }

    #tarteaucitron #tarteaucitronServices .tarteaucitronTitle a {
      font-size: 14px;
    }

    #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronTitle {
      padding: 5px 10px;
      margin: 0;
    }

    #tarteaucitron #tarteaucitronInfo,
    #tarteaucitron #tarteaucitronServices .tarteaucitronDetails {
      color: #fff;
      display: none;
      font-size: 12px;
      font-weight: 500;
      margin-top: 0;
      max-width: 270px;
      padding: 20px;
      position: absolute;
      z-index: 2147483647;
    }

    #tarteaucitron #tarteaucitronInfo a {
      color: #fff;
      text-decoration: underline;
    }

    #tarteaucitron #tarteaucitronServices .tarteaucitronLine:hover {
      background: rgba(51, 51, 51, 0.2);
    }

    #tarteaucitron #tarteaucitronServices .tarteaucitronLine {
      background: rgba(51, 51, 51, 0.1);
      border-left: 5px solid transparent;
      margin: 0;
      overflow: hidden;
      padding: 20px 15px;
    }

    #tarteaucitron #tarteaucitronServices .tarteaucitronMainLine {
      background: #333;
      border: 3px solid #333;
      border-left: 9px solid #333;
      border-top: 5px solid #333;
      margin-bottom: 0;
      margin-top: 21px;
      position: relative;
    }

    #tarteaucitron #tarteaucitronServices .tarteaucitronMainLine:hover {
      background: #333;
    }

    #tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName {
      margin-left: 15px;
      margin-top: 2px;
    }

    #tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName button {
      color: #fff;
    }

    #tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronAsk {
      margin-top: 0px !important;
    }

    #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronName {
      display: inline-block;
      float: left;
      margin-left: 10px;
      text-align: left;
      width: 50%;
    }

    #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronName a:hover {
      text-decoration: underline;
    }

    #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk {
      display: inline-block;
      float: right;
      margin: 7px 15px 0;
      text-align: right;
    }

    #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk .tarteaucitronAllow,
    #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk .tarteaucitronDeny,
    .tac_activate .tarteaucitronAllow {
      background: gray;
      border-radius: 4px;
      color: #fff;
      cursor: pointer;
      display: inline-block;
      padding: 6px 10px;
      text-align: center;
      text-decoration: none;
      width: auto;
      border: 0;
    }

    #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronName .tarteaucitronListCookies {
      color: #333;
      font-size: 12px;
    }

    #tarteaucitron .tarteaucitronH3 {
      font-size: 18px;
    }

    #tarteaucitron #tarteaucitronMainLineOffset .tarteaucitronName {
      width: auto!important;
      margin-left: 0!important;
      font-size: 14px;
    }


  /***
  * Better scroll management
  */
  div#tarteaucitronMainLineOffset {
    margin-top: 0!important;
  }

  div#tarteaucitronServices {
    margin-top: 21px!important;
  }

  #tarteaucitronServices::-webkit-scrollbar {
    width: 5px;
  }

  #tarteaucitronServices::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0 rgba(0,0,0,0);
  }

  #tarteaucitronServices::-webkit-scrollbar-thumb {
    background-color: #ddd;
    outline: 0px solid slategrey;
  }

  div#tarteaucitronServices {
    box-shadow: 0 0 35px #575757;
  }

  /***
   * Responsive layout for the control panel
   */
  @media screen and (max-width:479px) {
    #tarteaucitron .tarteaucitronLine .tarteaucitronName {
      width: 90% !important;
    }

    #tarteaucitron .tarteaucitronLine .tarteaucitronAsk {
      float: left !important;
      margin: 10px 15px 5px;
    }
  }

  @media screen and (max-width:767px) {
    #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer, #tarteaucitron {
      background: #fff;
      border: 0 !important;
      bottom: 0 !important;
      height: 100% !important;
      left: 0 !important;
      margin: 0 !important;
      max-height: 100% !important;
      max-width: 100% !important;
      top: 0 !important;
      width: 100% !important;
    }

    #tarteaucitron .tarteaucitronBorder {
      border: 0 !important;
    }

    #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList {
      border: 0 !important;
    }

    #tarteaucitron #tarteaucitronServices .tarteaucitronTitle {
      text-align: left !important;
    }

    .tarteaucitronName .tarteaucitronH2 {
      max-width: 80%;
    }

    #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk {
      text-align: center !important;
    }

    #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk button {
      margin-bottom: 5px;
    }
  }

  @media screen and (min-width:768px) and (max-width:991px) {
    #tarteaucitron {
      border: 0 !important;
      left: 0 !important;
      margin: 0 5% !important;
      max-height: 80% !important;
      width: 90% !important;
    }
  }

  /***
   * Fallback activate link
   */
  .tac_activate {
    background: #333;
    color: #fff;
    display: table;
    font-size: 12px;
    height: 100%;
    line-height: initial;
    margin: auto;
    text-align: center;
    width: 100%;
  }

  .tac_float {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }

  .tac_activate .tac_float strong {
    color: #fff;
  }

  .tac_activate .tac_float .tarteaucitronAllow {
    background-color: #1B870B;
    display: inline-block;
  }

  /***
   * CSS for services
   */
  ins.ferank-publicite, ins.adsbygoogle {
    text-decoration: none;
  }

  div.amazon_product {
    height:240px;
    width:120px;
  }
  .tarteaucitronIsAllowed .tarteaucitronDeny {
    opacity: 0.4!important;
  }.tarteaucitronIsDenied .tarteaucitronAllow {
     opacity: 0.4!important;
   }.tarteaucitronIsAllowed .tarteaucitronAllow {
      opacity: 1!important;
    }.tarteaucitronIsDenied .tarteaucitronDeny {
       opacity: 1!important;
     }
  .tarteaucitronLine .tarteaucitronAllow, .tarteaucitronLine .tarteaucitronAllow {
    opacity: 0.4;
  }

  div#tarteaucitronInfo {
    display: block!important;
    position: initial!important;
    text-align: center!important;
    max-width: 80%!important;
    padding: 15px 0!important;
    margin: -10px auto 40px!important;
    font-size: 1em!important;
    border-bottom: 1px solid;
    border-top: 1px solid;
    border-color: #555;
  }

  a.tarteaucitronSelfLink {
    position: absolute;
    left: 0;
    right: 0;
    padding-top: 13px!important;
    display: block;
    text-shadow: 0 0 14px white;
    text-transform: uppercase;
  }.tarteaucitronMainLine .tarteaucitronH2 {
     font-size: 1.2em!important;
     margin-top: 4px!important;
   }

  span.tarteaucitronTitle.tarteaucitronH3 {
    margin-top: 12px!important;
  }
