/* ==========================================================================
   Layout / Block Packaging Product
   ========================================================================== */

  .blockpackaging {
    @include wrapper('min');
    margin-bottom: 50px;

    @include breakpoint(tablet)  {
      margin-bottom: 90px;
    }

    @include breakpoint(tablethor)  {
      display: flex;
      margin-bottom: 100px;
    }

    &-infos {
      flex-basis: 50%;

      .listing {
        display: block;
        box-sizing: border-box;
        margin-left: 35px;

        @include breakpoint(phablet) {
          display: flex;
          flex-wrap: wrap;
          margin-left: 50px;
          margin-bottom: 40px;
        }

        @include breakpoint(tablet) {
          margin-bottom: 50px;
        }

        @include breakpoint(tablethor) {
          margin-bottom: 0;
        }

        div {
          flex-basis: 50%;
          padding-right: 20px;
          box-sizing: border-box;
          margin-bottom: 30px;

          @include breakpoint(phablet) {
            margin-bottom: 0;
          }

          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            flex-basis: 45%;
          }
        }

        h3 {
          display: block;
          font-family: $font-stack-title;
          font-size: 20px;
          font-weight: 600;
          font-style: normal;
          line-height: 1.35;
          letter-spacing: normal;
          text-transform: uppercase;
          margin-bottom: 20px;
        }

        ul li {
          list-style-type: none;
          font-size: 15px;
          font-weight: 900;
          color: $blackColor;
          line-height: 1.47;
          letter-spacing: normal;
          position: relative;
          padding-left: 20px;

          &:not(:last-child) {
            margin-bottom: 30px;
          }

          &:before {
            content: ' ';
            display: block;
            width: 6px;
            height: 6px;
            background: $blueColor;
            border-radius: 800px;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto 0;
          }

          h4 {
            margin: 0;
            padding: 0;
          }
        }
      }
    }

    &-spotlight {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      flex-basis: 45%;
      border-radius: 30px 0;
      box-sizing: border-box;
      text-align: center;
      background: $darkBlue;
      color: $whiteColor;
      padding: 45px 30px;
      margin-top: 15px;
      margin-bottom: 2px;

      @include breakpoint(phablet) {
        padding: 65px 30px;
      }

      @include breakpoint(laptop)  {
        flex-basis: 35%;
        margin-top: 50px;
        padding: 45px 30px;
      }

      h3 {
        display: block;
        font-family: $font-stack-title;
        font-size: 30px;
        font-weight: 600;
        line-height: 1.33;
        letter-spacing: normal;
        margin-bottom: 15px;

        @include breakpoint(tablet) {
          margin-bottom: 25px;
        }
      }

      p {
        display: block;
        margin: 0 auto;
        line-height: 1.24;

        @include breakpoint(tablet) {
          width: 70%;
        }

        span {
          font-weight: 300!important;
        }
      }
    }
  }