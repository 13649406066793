/* ==========================================================================
   Page / Page Gamme
   ========================================================================== */

  .rangepage {
    @include wrapper('min');
    width: 88%;
    margin-bottom: 50px;

    @include breakpoint(tablethor) {
      margin-bottom: 90px;
    }

    &-subcategories {
      display: none;
      box-sizing: border-box;
      border-radius: 0 30px;
      font-family: $font-stack-title;
      font-size: 20px;
      font-weight: 500;
      line-height: 1;
      background: $greyLight;
      padding: 30px 20px 40px 35px;
      margin: 0 0 25px;
      position: relative;
      z-index: 5;

      @include breakpoint(phablet) {
        padding: 30px 45px;
        margin: 0 10px 30px;
      }

      @include breakpoint(tablet) {
        text-align: center;
        padding: 25px 20px 20px;
        margin: 0 10px 30px;
      }

      @include breakpoint(tablethor) {
        margin: 0 10px 40px;
        padding: 15px 20px 0px;
      }

      .title {
        display: block;
        vertical-align: middle;
        margin-right: 47px;
        margin-bottom: 25px;

        @include breakpoint(595px) {
          display: inline-block;
          margin-bottom: 0;
        }

        @include breakpoint(tablet) {
          margin-bottom: 15px;
        }

        @include breakpoint(tablethor) {
          display: inline-block;
          vertical-align: top;
          margin: 8px 47px 8px 0;
        }
      }

      ul {
        display: inline-block;
        vertical-align: middle;
        position: relative;
        z-index: 5;

        @include breakpoint(tablet) {
          display: block;
        }

        @include breakpoint(tablethor) {
          display: inline-block;
          vertical-align: top;
          margin: 8px 0;
        }

        li {
          list-style-type: none;
          display: block;

          @include breakpoint(tablet) {
            display: inline-block;
          }

          @include breakpoint(tablethor) {
            margin-bottom: 15px;
          }

          &:not(:last-child) {
            margin-bottom: 18px;

            @include breakpoint(tablet) {
              margin-right: 25px;
            }

            @include breakpoint(laptop) {
              margin-right: 45px;
            }
          }
        }
      }
    }

    &-products {
      position: relative;
      z-index: 5;

     @include breakpoint(phablet) {
       display: flex;
       flex-wrap: wrap;
     }

      .productcard {
        height: auto;
        margin-bottom: 20px;

        @include breakpoint(phablet) {
          flex-basis: calc(48% - 10px);
          width: calc(48% - 10px);
          margin: 0 10px 25px;
        }

        @include breakpoint(950px) {
          flex-basis: calc(32% - 10px);
          width: calc(32% - 10px);
        }

        @include breakpoint(laptop) {
          flex-basis: calc(24% - 10px);
          width: calc(24% - 10px);
        }

        @include breakpoint(1400px) {
          flex-basis: calc(24.2% - 10px);
          width: calc(24.2% - 10px);
        }
      }

      .noresults {
        display: block;
        font-size: 20px;
        padding: 25px;
      }
    }

    &-pagination {
      display: block;
      background: transparent;
      text-align: center;
      margin: 30px 0 20px;
      box-shadow: 0 0 0 0 transparent;
      z-index: 5;

      a, .current {
        display: inline-block;
        width: 35px;
        border: 1px solid darken($greyLight, 20%);
        font-size: 16px;
        font-weight: 500;
        padding: 6px 0 6px;
        text-align: center;
        margin-bottom: 10px;
        margin-right: 1px;
        position: relative;
        z-index: 5;
      }

      a:hover {
        background: $blueColor;
        color: $whiteColor;
        border-color: $blueColor;
      }

      .current {
        background: $blueColor;
        color: $whiteColor;
        font-weight: 700;
        border: 1px solid $blueColor;
      }
    }
  }