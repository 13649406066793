/* ==========================================================================
   Components / Formulaires
   ========================================================================== */

  :-webkit-autofill {
    box-shadow: 0 0 0px 1000px $whiteColor inset;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type=number] {
    -moz-appearance:textfield;
  }

  select::-ms-expand {
    display: none;
  }

  .label {
    display: block;
    font-weight: 600;
    font-family: $font-stack-title;
    font-size: 16px;
    margin: 0;

    b {
      color: $redColor;
    }
  }

  input, textarea, select {
    display: block;
    width: 100%;
    padding: 15px 25px;
    font-size: 15px;
    color: $greyText;
    line-height: 1.2;
    font-family: $font-stack-common;
    border: 1px solid darken($greyLight, 10%);
    border-radius: 0 15px;
    box-sizing: border-box;
    margin: 5px 0 8px;

    &:focus {
      outline-style: none;
      border-color: $orange;
    }

    &.error {
      border-color: $redColor;
    }

    &.valid {
      border-color: $greenColor;
    }
  }

  input[type="checkbox"] {
    display: none;

    & + label, & + span {
      display: block;
      line-height: 20px;
      font-size: 15px;
      font-weight: 600;
      color: $greyText;
      font-family: $font-stack-common;
      padding-left: 35px;
      position: relative;
      cursor: pointer;

      &:before {
        content: ' ';
        display: block;
        width: 19px;
        height: 19px;
        border-radius: 4px;
        border: 2px solid darken($greyLight, 15%);
        transition: all $duration $easeInOutBack;
        position: absolute;
        top: 0;
        left: 0;
      }

      &:after {
        content: ' ';
        display: block;
        width: 19px;
        height: 19px;
        background: url('images/icone-check-orange.svg') center center/100% auto no-repeat;
        transition: all $duration $easeInOutBack;
        transform: scale(0);
        position: absolute;
        top: 0;
        left: 4px;
      }
    }
  }

  input[type="checkbox"]:checked + label, input[type="checkbox"]:checked + span {
    &:before {
      border-color: $orange;
      transition: all $duration $easeInOutBack;
    }

    &:after{
      transform: scale(1);
      transition: all $duration $easeInOutBack;
    }
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: $whiteColor url("images/arrow.svg") 95% 54%/18px auto no-repeat;
  }

  .form {
    &-column {
      width: 100%;
      margin-bottom: 15px;

      @include breakpoint(tablet) {
        margin-bottom: 20px;
      }
    }

    &-row {
      @include breakpoint(laptop) {
        display: inline-block;
        vertical-align: top;
        width: 48%;

        input {
          margin-bottom: 0;
        }

        &:first-child {
          margin-right: 3%;
        }

        select {
          background-position: 88% 54%;
        }
      }
    }

    &-input {
      .btn-main {
        display: table;
        margin: auto;
      }
    }
  }

  .wpcf7-form {
    position: relative;
  }

  .loader {
    display: block;
    width: 200px;
    height: 200px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }

  .wpcf7-form-control {
    display: block;

    .wpcf7-list-item {
      vertical-align: top;
      width: 44%;
      margin-right: 0!important;

      @include breakpoint(tablet) {
        width: 30%;
      }

      @include breakpoint(tablethor) {
        width: 44%;
      }

      @include breakpoint(laptop) {
        width: 33%;
      }
    }

    &.wpcf7-checkbox {
      margin-top: 20px;
    }
  }

  #rgpd {
    display: block;
    width: 100%;

    .wpcf7-list-item {
      width: 100%;
    }

    .wpcf7-list-item-label {
      font-weight: 400;
      font-size: 12px;

      &:before {
        top: 5px;
      }

      &:after {
        top: 5px;
      }
    }
  }

  .rpgd-column {
    .error-msg {
      position: relative;
      bottom: 8px;
      left: 30px;
      margin-bottom: 25px;
    }
  }

  span.wpcf7-list-item {
    display: inline-block;
    margin: 0 15px 15px 0!important;
  }

  .required {
    font-size: 15px;
    text-align: left;
    color: $redColor;
    margin-left: 35px;
    margin-top: -15px;
    margin-bottom: 20px;
  }

  .error-msg {
    display: none;
    font-size: 13px;
    font-family: $font-stack-title;
    font-weight: 400;
    color: $redColor;
    margin: 5px 5px 0;
  }

  .contentform {
    &.disabled {
      opacity: 0.1;
      pointer-events: none;
      cursor: default;
    }
  }

  .validation-msg {
    display: none;
    width: 100%;
    text-align: center;

    h3 {
      @extend .h2-title;
    }

    .btn {
      margin: 25px auto;
      min-width: 215px;
      padding: 15px 25px;
    }
  }
