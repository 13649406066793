/* ==========================================================================
   Components / Pagination
   ========================================================================== */

  .navigation.pagination {
    display: block;
    background: transparent;
    text-align: center;
    margin: 30px 0 20px;
    box-shadow: 0 0 0 0 transparent;
    z-index: 5;

    a, .current {
      display: inline-block;
      width: 35px;
      background: $whiteColor;
      border: 1px solid darken($greyLight, 20%);
      font-size: 16px;
      font-weight: 500;
      padding: 6px 0 6px;
      text-align: center;
      margin-bottom: 10px;
      margin-right: 1px;
    }

    a:hover {
      background: $blueColor;
      color: $whiteColor;
      border-color: $blueColor;
    }

    .current {
      background: $blueColor;
      color: $whiteColor;
      font-weight: 700;
      border: 1px solid $blueColor;
    }
  }