/* ==========================================================================
   Layout / Block administration
   ========================================================================== */

  .blockadministration {
    @include wrapper('min');
    margin-bottom: 50px;

    @include breakpoint(tablet) {
      margin-bottom: 90px;
    }

    @include breakpoint(tablethor) {
      margin-bottom: 120px;
    }

    &-flex {
      @include breakpoint(tablethor) {
        display: flex;
        justify-content: space-between;
      }
    }

    &-text {
      margin-left: 15px;
      margin-right: 5px;
      margin-bottom: 30px;

      @include breakpoint(phablet) {
        margin-left: 50px;
        margin-bottom: 40px;
      }

      @include breakpoint(tablethor) {
        flex-basis: calc(45% - 50px);
        margin-left: 50px;
        margin-bottom: 0;
      }

      .administration {
        &-title {
          @extend .h4-title;
          font-weight: 500;
          margin-bottom: 15px;
        }

        &-mode {
          display: block;
          font-size: 17px;
          font-weight: 700;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.65;
          letter-spacing: normal;
          padding: 6px 0 6px 55px;
          position: relative;
          margin-bottom: 25px;

          &:before {
            content: ' ';
            display: block;
            width: 40px;
            height: 40px;
            background-size: 100% auto;
            background-position: center center;
            background-repeat: no-repeat;
            position: absolute;
            top: 0;
            left: 0;
          }

          &.tab:before { background-image: url('images/icone-tab.svg'); }
          &.water:before { background-image: url('images/icone-water.svg'); }
          &.top-feed:before { background-image: url('images/icone-top-feed.svg'); }
          &.environnement:before { background-image: url('images/icone-environnement.svg'); }
          &.feed:before { background-image: url('images/icone-feed.svg'); }
          &.feed-mix:before { background-image: url('images/icone-feed.svg'); }
          &.oral:before { background-image: url('images/icone-oral.svg'); }
        }
      }

      .explication {
        p, span {
          font-weight: 300!important;
        }

        p:not(:last-child) {
          margin-bottom: 20px;
        }

        h2 {
          font-size: 17px;
          font-weight: 800;
          margin-bottom: 15px;
        }

        h3 {
          font-size: 15px;
          font-weight: 700;
          margin-bottom: 15px;
        }

        ol li {
          display: flex;
          font-size: 15px;
          font-weight: 700;
          line-height: 1.47;
          letter-spacing: normal;
          color: $blackColor;
          position: relative;
          counter-increment: inst;

          &:not(:last-child) {
            margin-bottom: 30px;
          }

          &:before {
            content: counter(inst);
            color: $blueColor;
            font-size: 18px;
            font-weight: 900;
            line-height: 1;
            letter-spacing: normal;
            margin-top: 4px;
            margin-right: 15px;
          }
        }

        ul li {
          list-style-type: none;
          font-size: 15px;
          font-weight: 900;
          color: $blackColor;
          line-height: 1.47;
          letter-spacing: normal;
          position: relative;
          padding-left: 20px;

          &:not(:last-child) {
            margin-bottom: 30px;
          }

          &:before {
            content: ' ';
            display: block;
            width: 6px;
            height: 6px;
            background: $blueColor;
            border-radius: 800px;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto 0;
          }

          h4 {
            margin: 0;
            padding: 0;
          }
        }

        ul, ol {
          & + h2, & + h3 {
            margin-top: 30px;
          }

          & + p {
            margin-top: 20px;
          }
        }
      }
    }

    &-visual {
      border-radius: 30px 0;
      overflow: hidden;
      position: relative;

      @include breakpoint(tablet) {
        width: 95%;
        margin: auto;
      }

      @include breakpoint(tablethor) {
        display: flex;
        justify-content: center;
        width: 100%;
        flex-basis: 50%;
        min-height: 305px;
        margin: -60px 0 0 0;
      }

      &:before {
        content: ' ';
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }

      a {
        display: block;
        width: 100%;
        height: 100%;
        overflow: hidden;

        &:before {
          content: ' ';
          display: block;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.2);
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
        }

        .icon {
          display: block;
          width: 75px;
          height: 75px;
          fill: $orange;
          opacity: 1;
          text-shadow: 0 0 50px 25px rgba(0,0,0,0.5);
          transition: all $duration $easeInOutCubic;
          position: absolute;
          top: calc(50% - 37px);
          left: 0;
          right: 0;
          margin: auto;
          z-index: 2;
        }

        &:hover {
          img {
            transform: scale(1.04);
            transition: all $duration $easeInOutCubic;
          }

          .icon {
            fill: $orange;
            opacity: 0.9;
            transition: all $duration $easeInOutCubic;
          }
        }
      }

      img {
        display: block;
        width: 100%;
        height: auto;
        transition: all $duration $easeInOutCubic;
        top: 0;
        z-index: 0;

        @include breakpoint(tablethor) {
          width: auto;
          height: 100%;
          min-width: 100%;
          position: absolute;
          object-fit: cover;

          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            width: auto;
            height: auto;
            min-height: 100%;
            min-width: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }

      &.hasVideo:before { display: none; }
    }
  }