/* ==========================================================================
   Components / Document
   ========================================================================== */

  .document {
    display: block;
    width: 100%;
    border-radius: 30px 0;
    padding-bottom: 45px;
    background: $greyLight;
    box-sizing: border-box;
    text-align: center;
    overflow: hidden;
    position: relative;
    margin-bottom: 20px;

    &:before {
      content: ' ';
      display: block;
      width: 58px;
      height: 58px;
      border-radius: 30px 0;
      background: $blueColor;
    }

    .icon {
      display: block;
      fill: $whiteColor;
      width: 30px;
      height: 30px;
      position: absolute;
      top: 13px;
      left: 14px;
    }

    &-title {
      display: block;
      font-family: $font-stack-title;
      padding: 6px 50px 17px;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.35;
      letter-spacing: normal;
      text-align: center;
    }

    &-desc {
      padding: 0 30px;
      margin-bottom: 15px;
    }

    .btn {
      display: table;
      min-width: 190px;
      margin: auto;
    }

    &.has-link {
      .document-title {
        padding-top: 14px;
      }
      .document-desc {
        margin-bottom: 30px;
      }
      &:before {background: $orange; }
    }
  }