/* ==========================================================================
   Layout / Block News
   ========================================================================== */

  .newsblock {
    @include wrapper('min');
    margin-bottom: 95px;
    overflow: visible;

    @include breakpoint(tablethor) {
      margin-bottom: 185px;
    }

    .title-special {
      margin: 0;
    }

    &-flex {
      @include breakpoint(tablethor) {
        display: flex;
      }
    }

    &-latest {
      margin-top: 30px;
      margin-bottom: 40px;

      @include breakpoint(phablet) {
        margin-bottom: 50px;
      }

      @include breakpoint(tablet) {
        margin-top: 40px;
        margin-bottom: 60px;
      }

      @include breakpoint(tablethor) {
        flex-basis: 60%;
        margin-bottom: 0;
      }

      @include breakpoint(desktop) {
        flex-basis: 75%;
        margin-top: 60px;
      }

      .newsflexbox {
        display: none;
        margin-bottom: 25px;

        &.slick-initialized {
          display: block;
        }

        @include breakpoint(phablet) {
          display: flex;
          margin-bottom: 35px;
        }

        .slick-prev, .slick-next {
          background-color: $greyLight;
          background-size: 50% auto;
          background-position: 10px center;
          border-radius: 80px;
          padding: 18px;
          top: 15%;

          @include breakpoint(phablet) {
            padding: 20px;
          }
        }

        .slick-next {
          right: 15px;
        }

        .slick-prev {
          left: 15px;
        }
      }

      .card {
        @include breakpoint(phablet) {
          flex-basis: calc(50% - 10px);
          margin-right: 20px;
        }

        @include breakpoint(desktop) {
          flex-basis: calc(33% - 10px);
          margin-right: 20px;
        }

        &:nth-child(2) {
          @include breakpoint(phablet) {
            margin-right: 0;
          }

          @include breakpoint(tablehtor) {
            margin-right: 10px;
          }

          @include breakpoint(desktop) {
            margin-right: 20px;
          }
        }

        &:nth-child(3) {
          @include breakpoint(phablet) {
            display: none;
          }
          @include breakpoint(desktop) {
            display: flex;
            margin-right: 0;
          }
        }

        &-content {
          display: block;
          align-items: flex-start;
          background: #f7f7f7;
          box-sizing: border-box;
          padding: 25px 30px 35px;

          @include breakpoint(phablet) {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            height: inherit;
            flex-grow: 1;
          }
        }
      }

      .btn-secondary {
        margin: auto;

        @include breakpoint(phablet) {
          margin: 0;
        }
      }

      &.fullwidth {
        flex-basis: 100%;

        .card {
          flex-basis: calc(25% - 17px);
        }
      }
    }

    &-events {
      display: block;
      padding: 40px 0 30px;
      box-sizing: border-box;
      background: $greyLight;
      border-radius: 50px;
      position: relative;
      overflow: visible;
      margin-bottom: 25px;

      &:after {
        content: ' ';
        display: block;
        background: $greyLight;

        width: 120vw;
        height: 100%;
        position: absolute;
        top: 0;
        left: -10vw;
        z-index: 0;

        @include breakpoint(tablethor) {
          width: 100vw;
          border-radius: 120px 0 0 120px;
          left: 0;
        }
      }

      @include breakpoint(tablet) {
        padding: 40px 0;
      }

      @include breakpoint(tablethor) {
        flex-basis: 37%;
        padding: 50px 0 45px 35px;
        border-radius: 120px 0 0 120px;
        margin-left: 20px;
      }

      @include breakpoint(laptop) {
        padding: 50px 0 45px 55px;
      }

      @include breakpoint(desktop) {
        flex-basis: 30%;
        padding: 50px 17px 45px 45px;
      }

      @include breakpoint(mediumdesktop) {
        padding: 50px 17px 45px 65px;
      }

      .title-special-right {
        padding-left: 0;
        margin-bottom: 35px;
        position: relative;
        z-index: 1;

        @include breakpoint(tablethor) {
          margin-bottom: 25px;
        }

        @include breakpoint(laptop) {
          margin-bottom: 45px;
        }

        @include breakpoint(desktop) {
          margin-bottom: 35px;
        }

        &:before {
          background-image: url('images/titre-forme-orange.svg');
        }
      }

      .events {
        display: block;
        position: relative;
        z-index: 1;

        @include breakpoint(tablethor) {
          min-height: 380px;
          padding-bottom: 95px;
        }

        &-container {
          @include breakpoint(phablet) {
            display: flex;
            justify-content: space-between;
          }

          @include breakpoint(tablethor) {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 335px;
          }

          @include breakpoint(laptop) {
            width: 365px;
          }

          @include breakpoint(desktop) {
            width: 375px;
          }
        }
      }

      .event {
        display: block;
        width: 100%;
        background: $whiteColor;
        padding: 20px 27px 25px;
        box-sizing: border-box;
        text-align: center;
        border-radius: 0 30px;
        margin-bottom: 20px;

        @include breakpoint(phablet) {
          flex-basis: 48.5%;
          width: 48.5%;

          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            flex-basis: 37.5%;
            width: 37.5%;
          }
        }

        @include breakpoint(tablet) {
          flex-basis: 49%;
          width: 49%;
          padding: 30px 27px;
          min-height: 190px;

          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            flex-basis: 41%;
            width: 41%;
          }
        }

        @include breakpoint(tablethor) {
          display: flex;
          align-items: center;
          flex-direction: row-reverse;
          justify-content: flex-start;
          text-align: right;
          width: 100%;
          padding: 40px 27px 30px;
        }

        &:first-child {
          border-radius: 30px 0;
        }

        &-text {
          display: block;
          font-size: 17px;
          max-width: 100%;

          @include breakpoint(tablethor) {
            flex-basis: calc(100% - 110px);
            width: calc(100% - 110px);
          }
        }

        &-date {
          max-width: 100%;
          color: $orange;
          font-weight: 500;
          font-size: 18px;
          line-height: normal;
          margin-bottom: 5px;
        }

        &-place {
          max-width: 100%;
          font-size: 19px;
          font-weight: 900;
          line-height: normal;
          margin-bottom: 2px;
        }

        &-title {
          max-width: 100%;
          line-height: 1.5;
          letter-spacing: -0.03rem;
        }

        &-img {
          @include breakpoint(760px) {
            margin-left: 20px;
          }

          @include breakpoint(tablethor) {
            width: 90px;
            flex-basis: 90px;
            height: auto;
          }
        }
      }
    }
  }