/* ==========================================================================
   Components / Menu Navigation
   ========================================================================== */

  .nav-menu {
    display: block;
    width: 100%;
    margin: 0 0 0 auto;
    box-sizing: border-box;
    position: fixed;
    overflow-y: auto;
    overflow-x: hidden;
    top: 70px;
    right: -100%;
    height: calc(100vh - 70px);
    padding-bottom: 5px;
    background: $darkBlue;
    transition: all $durationSmall $easeInOutQuint;
    z-index: 6;

    @include breakpoint(phablet) {
      width: 375px;
    }

    @include breakpoint(tablet) {
      width: 365px;
      top: 75px;
      height: calc(100vh - 75px);
    }

    @include breakpoint(tablethor) {
      width: 385px;
    }

    @include breakpoint(laptop) {
      display: table;
      width: auto;
      height: auto;
      background: transparent;
      text-align: right;
      padding-top: 0;
      padding-right: 65px;
      padding-bottom: 0;
      position: relative;
      overflow: inherit;
      top: 0;
      right: 0;
    }

    @include breakpoint(mediumdesktop) {
      padding-right: 160px;
    }

    &.open {
      right: 0;
      transition: all $durationSmall $easeInOutCirc;
    }
  }

  /* Premier niveau de menu */
  .nav-menu > .menu-item {
    display: block;
    width: 100%;
    text-align: left;
    list-style-type: none;
    font-size: 15px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    position: relative;

    @include breakpoint(laptop) {
      display: inline-block;
      width: auto;
      border: 0;
      margin-left: 45px;
    }

    @include breakpoint(desktop) {
      margin-left: 58px;
    }

    a {
      display: block;
      width: 100%;
      color: $whiteColor;
      padding: 22px 30px;
      text-decoration: none;
      position: relative;
      transition: all $duration $easeInOutCirc;

      @include breakpoint(laptop) {
        width: auto;
        background: transparent;
        padding: 0 0 20px 0;

        &:after {
          content: ' ';
          display: block;
          width: 0;
          height: 8px;
          background: $orange;
          position: absolute;
          left: 0;
          bottom: 0;
          transition: all $duration $easeInOutCirc;
        }
      }

      &:hover {
        &:after {
          width: 100%;
          transition: all $duration $easeInOutCirc;
        }
      }
    }

    &:hover > div {
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
      transform: translateY(0);
      transition: all $duration $easeInOutCirc;
    }

    &.current-menu-item a {
      color: $orange;

      @include breakpoint(laptop) {
        color: $whiteColor;
      }

      &:after {
        width: 100%;
        transition: all $duration $easeInOutCirc;
      }
    }

    &.menu-item-has-children a:before {
      content: ' ';
      display: block;
      width: 14px;
      height: 20px;
      background: url('images/arrow-white.svg') center center/14px auto no-repeat;
      transition: all $duration $easeInOutCirc;
      position: absolute;
      top: 24px;
      right: 23%;

      @include breakpoint(laptop) {
        display: none;
      }
    }
    &.menu-item-has-children.active {
      a {
        padding: 22px 30px 20px;

        @include breakpoint(laptop) {
          padding: 0 0 20px 0;
        }

        &:before {
          transform: rotate(-90deg);
          transition: all $duration $easeInOutCirc;
        }
      }
    }
  }

  /* CONTAINER BIG MENU */
  .nav-menu .menu-item > div {
    display: block;
    max-height: 0;
    transition: all $duration $easeInOutCirc;
    overflow: hidden;

    @include breakpoint(laptop) {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      padding-top: 20px;
      min-width: 805px;
      max-height: inherit;
      overflow: inherit;
      transform: translateY(20px);
      pointer-events: none;
      left: -220px;
      top: 100%;
      z-index: 5;
    }

    &.open {
      max-height: 400px;
      transition: all $duration $easeInOutCirc;
    }

    li.menu-classic {
      list-style-type: none;

      @include breakpoint(laptop) {
        display: block;
        flex: 55%;
        width: 55%;
        max-width: 55%;
        margin-left: 0;
        position: relative;
        vertical-align: top;
        height: 100%;

        &:before {
          content: ' ';
          display: block;
          width: 15px;
          height: 15px;
          border-radius: 3px;
          background: $whiteColor;
          box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
          position: absolute;
          top: -6px;
          right: 65px;
          transform: rotate(45deg);
          z-index: 0;
        }
      }
    }

    li.menu-addon {
      display: none;

      @include breakpoint(laptop) {
        display: block;
        flex: 45%;
        width: 45%;
        max-width: 45%;
        box-sizing: border-box;
        text-align: center;
        margin-left: 0;
        position: static;
        vertical-align: top;
        border-radius: 0 20px 20px 0;
        overflow: hidden;
        height: 100%;
      }

    }

    li.menu-img {
      img {
        display: block;
        width: 100%;
        height: auto;
        opacity: 1!important;
      }
    }

    li.menu-txt {
      background: $orange;

      > div {
        display: flex;
        flex-flow: column;
        justify-content: center;
        height: 100%;
        color: $whiteColor;
        padding: 25px 40px;
        box-sizing: border-box;

        strong {
          font-family: $font-stack-title;
          font-size: 20px;
          margin-top: 15px;
          margin-bottom: 15px;
        }

        p {
          font-weight: 300;
          font-family: $font-stack-common;
          font-size: 16px;
          line-height: 1.5;
        }

        .btn {
          display: table;
          width: auto;
          min-width: inherit;
          border-color: $whiteColor;
          margin: 20px auto 0;
          line-height: 1.2;
          padding: 12px 45px 14px;

          &:after, &:before {
            display: none;
          }

          &:hover {
            color: $orange;
            background: $whiteColor;
          }
        }

        .btn-download {
          color: $blackColor;
          background: $whiteColor;
          padding: 12px 40px 15px;
          line-height: 1.1;

          &:hover .icon {
            fill: $orange;
          }
        }
      }
    }
  }

  .nav-menu .menu-item:nth-child(4) > div {
    @include breakpoint(laptop) {
      left: -305px;
      li.menu-classic:before { right: 20px; }
    }

    @include breakpoint(desktop) {
      left: -270px;
      li.menu-classic:before { right: 25px; }
    }

    @include breakpoint(mediumdesktop) {
      left: -235px;
      li.menu-classic:before { right: 60px; }
    }
  }

  /* BIG MENU (niveau de menu 2) */
  .nav-menu .menu-item > div > .sub-menu {
    display: block;
    padding-left: 45px;
    margin-bottom: 25px;

    @include breakpoint(laptop) {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      background: $whiteColor;
      padding: 0;
      width: 100%;
      height: 100vh;
      max-height: 269px;
      //max-height: 295px;
      max-width: 630px;
      box-sizing: border-box;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
      border-radius: 20px;
      position: relative;
    }

    .menu-item {
      display: block;
      width: 100%;
      margin-bottom: 20px;

      @include breakpoint(laptop) {
        text-align: left;
        margin-left: 0;
        margin-bottom: 25px;
      }

      a {
        display: block;
        color: $whiteColor;
        font-size: 15px;
        font-weight: 400;
        background: transparent;
        padding: 0;
        margin-bottom: 15px;
        transition: all $durationSmall $easeInOutCirc;

        @include breakpoint(laptop) {
          color: $greyText;
          font-size: 15px;
          font-weight: 900;
          margin-bottom: 10px;
        }

        &:after, &:before {
          display: none;
        }

        &:hover {
          color: $orange;
          transition: all $durationSmall $easeInOutCirc;
        }
      }

      &.current-menu-item > a {
        color: $orange;
      }
    }

    .sub-menu-classic {
      @include breakpoint(laptop) {
        display: block;
        width: 95%;
        box-sizing: border-box;
        padding: 40px 45px 0;
        background: $whiteColor;
        border-radius: 20px 0 20px 0;
        position: relative;
        left: 2px;
        z-index: 1;
      }
    }
  }

  /* SOUS-MENU 3e niveau */
  .nav-menu > .menu-item > div > .sub-menu > .menu-classic > .sub-menu-classic > .menu-item > .sub-menu {
    display: block;
    width: auto;
    height: auto;
    position: static;
    min-height: 0;
    margin-left: 20px;
    border-radius: 0;
    transform: none;
    box-shadow: none;
    opacity: 1;
    visibility: visible;

    @include breakpoint(laptop) {
      padding-bottom: 0;
      margin-bottom: 28px;
    }

    li {
      display: block;
      float: inherit;
      width: auto;
      border: 0;

      &:last-child {
        margin-bottom: 0;

        a {
          margin-bottom: 0;
        }
      }

      a {
        display: block;
        font-size: 15px;
        padding-bottom: 0;
        font-weight: normal;
        margin-bottom: 8px;
        transition: all $durationSmall $easeInOutCirc;

        @include breakpoint(laptop) {
          color: $greyText;
        }

        &:after, &:before {
          display: none;
        }

        &:hover {
          color: $blueColor;
          text-decoration: underline;
          transition: all $durationSmall $easeInOutCirc;
        }
      }

      &.current-menu-item a {
        color: $blueColor;
        text-decoration: underline;
      }
    }
  }

  /* Si on est dans le menu des produits, on change un peu le méga menu */
  .products-menu .sub-menu li.menu-classic .sub-menu-classic .menu-item {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 0;
    }

    @include breakpoint(laptop) {
      display: block;
      width: 30%;
      margin-bottom: 2px;

      &:first-child, &:nth-child(4n) {
        width: 40%;
      }
    }

    .sub-menu .menu-item {
      margin-bottom: 10px;
    }
  }

  .nav-menu .products-menu > div {
    @include breakpoint(laptop) {
      left: -320px;
    }

    & > .sub-menu {
      @include breakpoint(laptop) {
        max-height: 290px;
        max-width: 810px;

        .menu-item {
          float: left;
          width: 50%;
          margin-bottom: 20px;
        }

        .sub-menu-classic {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          padding: 31px 41px;
          left: 0;
        }
      }
    }
  }

  /* RESPONSIVE BURGER BUTTON */
  .burger {
    display: block;
    width: 35px;
    height: 45px;
    text-align: right;
    transition: all $duration $easeInOutBack;
    position: absolute;
    top: 23px;
    bottom: 0;
    right: 7%;
    z-index: 6;

    @include breakpoint(phablet) {
      top: 22px;
      right: 4%;
    }

    @include breakpoint(tablet) {
      top: 25px;
    }

    @include breakpoint(laptop) {
      display: none;
    }

    &-line {
      display: block;
      width: 100%;
      height: 4px;
      border-radius: 105px;
      background: $whiteColor;
      transition: all $durationIntermediate $easeInOutBack;
      position: relative;

      &:not(:last-child) {
        margin-bottom: 7px;
      }

      &:nth-child(2) {
        width: 70%;
        margin-left: auto;
        transition: all $duration $easeInOutBack;

        &:before {
          content: ' ';
          display: inline-block;
          width: 16%;
          height: 3px;
          background: $whiteColor;
          position: absolute;
          top: 0;
          left: -33%;
        }
      }
    }

    &.active {
      right: 7%;
      transition: all $duration $easeInOutBack;

      @include breakpoint(phablet) {
        right: 3.5%;
        top: 18px;
      }

      @include breakpoint(tablet) {
        top: 20px;
        right: 3%;
      }

      &:after {
        opacity: 0;
        transition: all $durationSmall $easeInOutCirc;
      }

      .burger-line {
        background: $whiteColor!important;

        &:first-child {
          transform: rotate(44deg) translateY(11px) translateX(12px);
          transition: all $durationIntermediate $easeInOutBack;
        }

        &:nth-child(2n) {
          width: 0%;
          transition: all $durationIntermediate $easeInOutBack;
        }

        &:last-child {
          transform: rotate(-43deg) translateY(-4px) translateX(4px);
          transition: all $durationIntermediate $easeInOutBack;
        }
      }
    }
  }
