/* ==========================================================================
   Components / Buttons
   ========================================================================== */

  button {
    background: transparent;
    border: 0;
    cursor: pointer;

    &:focus {
      outline-style: none;
    }
  }

  .btn {
    text-decoration: none;

    &:focus {
      outline-style: none;
    }
  }

  .btn-main {
    display: table;
    min-width: 268px;
    font-size: 15px;
    font-family: $font-stack-common;
    font-weight: 600;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: $orange;
    padding: 12px 25px 14px;
    box-sizing: border-box;
    border: 2px solid $orange;
    border-radius: 25px;
    transition: all $duration $easeOutQuint;

    &:hover {
      color: $whiteColor;
      background: $orange;
      transition: all $duration $easeOutQuint;
    }
  }

  .btn-main-white {
    display: table;
    min-width: 225px;
    font-size: 15px;
    font-family: $font-stack-common;
    font-weight: 600;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: $blackColor;
    padding: 17px 25px;
    box-sizing: border-box;
    background: $whiteColor;
    border-radius: 25px;
    transition: all $duration $easeOutQuint;

    &:hover {
      color: $whiteColor;
      background: $orange;
      transition: all $duration $easeOutQuint;
    }
  }

  .btn-secondary {
    display: table;
    min-width: 268px;
    font-size: 15px;
    font-family: $font-stack-common;
    font-weight: 600;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: $blackColor;
    padding: 12px 25px 14px;
    box-sizing: border-box;
    border: 1px solid $blackColor;
    border-radius: 25px;
    transition: all $duration $easeOutSine;

    &:hover {
      color: $whiteColor;
      background: $orange;
      border-color: $orange;
      transition: all $duration $easeOutSine;
    }
  }

  .btn-secondary-white {
    display: table;
    font-size: 15px;
    font-family: $font-stack-common;
    font-weight: 600;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: $whiteColor;
    padding: 11px 40px 13px;
    box-sizing: border-box;
    border: 1px solid $whiteColor;
    border-radius: 25px;
    margin-top: 30px;
    transition: all $duration $easeOutSine;

    &:hover {
      color: $greyText;
      background: $whiteColor;
      transition: all $duration $easeOutSine;
    }
  }

  .btn-header {
    display: table;
    min-width: 268px;
    font-size: 15px;
    font-family: $font-stack-common;
    font-weight: 600;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: $whiteColor;
    background: $orange;
    padding: 12px 25px 14px;
    box-sizing: border-box;
    border-radius: 25px;
    transition: all $duration $easeOutQuint;
    margin-top: 25px;
    position: relative;
    z-index: 3;

    &:hover {
      background: $blueColor!important;
      transition: all $duration $easeOutQuint;
    }
  }

  .btn-tertiary {
    display: table;
    font-size: 15px;
    font-family: $font-stack-common;
    font-weight: 600;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: $whiteColor;
    background: $blueColor;
    padding: 12px 25px 14px;
    box-sizing: border-box;
    border-radius: 25px;
    transition: all $duration $easeOutQuint;
    margin-top: 25px;
    position: relative;
    z-index: 3;

    .icon {
      display: inline-block;
      vertical-align: top;
      fill: $whiteColor;
      width: 20px;
      height: 20px;
      margin-left: 5px;
      margin-right: 5px;
      transition: all $duration $easeInOutBack;
    }

    .text {
      display: inline-block;
      vertical-align: top;
      padding: 0 10px;
    }


    &:hover {
      color: $whiteColor;
      background: $orange;
      transition: all $duration $easeOutQuint;

      .icon {
        transform: translateX(4px);
        transition: all $duration $easeInOutBack;
      }
    }
  }

  .btn-download {
    display: table;
    min-width: 225px;
    font-size: 15px;
    font-family: $font-stack-common;
    font-weight: 600;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: $blackColor;
    padding: 14px 25px;
    box-sizing: border-box;
    background: $whiteColor;
    border-radius: 25px;
    transition: all $duration $easeOutQuint;

    .icon {
      display: inline-block;
      vertical-align: top;
      width: 20px;
      height: 20px;
      fill: $greyText;
      transition: all $duration $easeOutQuint;
    }

    .text {
      display: inline-block;
      vertical-align: middle;
      width: auto!important;
      margin-right: 15px;
      font-size: 15px;
      position: relative;
      top: 1px;
    }

    &:hover {
      color: $whiteColor;
      background: $orange;
      transition: all $duration $easeOutQuint;

      .icon {
        fill: $whiteColor;
        transition: all $duration $easeOutQuint;
      }
    }
  }

  .btn-download-file {
    display: block;
    text-align: center;
    font-size: 15px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: normal;
    color: $orange;
    transition: all $duration $easeInOutQuart;

    .icon-download {
      display: block;
      width: 42px;
      height: 40px;
      fill: $orange;
      margin: auto auto 11px;
      transition: all $duration $easeInOutQuart;
    }

    &:hover {
      color: $blueColor;
      transition: all $duration $easeInOutQuart;

      .icon-download {
        fill: $blueColor;
        transform: translateY(2px);
        transition: all $duration $easeInOutQuart;
      }
    }
  }

  .btn-more {
    display: block;
    font-family: $font-stack-common;
    font-size: 15px;
    font-weight: 700;
    line-height: 0.8;
    color: $blackColor;
    transition: all $duration $easeInOutCirc;

    .text {
      display: inline-block;
      vertical-align: top;
      margin-right: 15px;
      margin-top: 2px;
    }

    .icon {
      display: inline-block;
      vertical-align: top;
      width: 20px;
      height: 20px;
      fill: $orange;
      transition: all $duration $easeInOutQuint;
    }

    &:hover {
      color: $orange;
      transition: all $duration $easeInOutCirc;

      .icon {
        fill: $blackColor;
        transform: translatex(5px);
        transition: all $duration $easeInOutQuint;
      }
    }
  }