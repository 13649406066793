
  // Déclaration des fonts utilisées ici et qui ont été convertie par gulp dans le dossier build/assets/fonts en woff et woff2

  @font-face {
    font-family: 'Gotham';
    src: local('Gotham Light'),
    url('./fonts/Gotham-Light.woff2') format('woff2'),
    url('./fonts/Gotham-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
  }

  @font-face {
    font-family: 'Gotham';
    src: local('Gotham Regular'),
    url('./fonts/Gotham-Book.woff2') format('woff2'),
    url('./fonts/Gotham-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
  }

  @font-face {
    font-family: 'Gotham';
    src: local('Gotham Medium'),
    url('./fonts/Gotham-Medium.woff2') format('woff2'),
    url('./fonts/Gotham-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
  }

  @font-face {
    font-family: 'Gotham';
    src: local('Gotham Bold'),
    url('./fonts/Gotham-Bold.woff2') format('woff2'),
    url('./fonts/Gotham-Bold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
  }

  @font-face {
    font-family: 'Gotham';
    src: local('Gotham Black'),
    url('./fonts/Gotham-Black.woff2') format('woff2'),
    url('./fonts/Gotham-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
  }


  @font-face {
    font-family: 'Avenir';
    src: local('Avenir Light'),
    url('./fonts/Avenir-Light.woff2') format('woff2'),
    url('./fonts/Avenir-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
  }

  @font-face {
    font-family: 'Avenir';
    src: local('Avenir Regular'),
    url('./fonts/Avenir-Book.woff2') format('woff2'),
    url('./fonts/Avenir-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
  }

  @font-face {
    font-family: 'Avenir';
    src: local('Avenir Medium'),
    url('./fonts/Avenir-Medium.woff2') format('woff2'),
    url('./fonts/Avenir-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
  }

  @font-face {
    font-family: 'Avenir';
    src: local('Avenir Black'),
    url('./fonts/Avenir-Black.woff2') format('woff2'),
    url('./fonts/Avenir-Black.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
  }