/* ==========================================================================
   Layout / Block Services
   ========================================================================== */

  .services {
    display: block;
    max-width: 1600px;
    margin-bottom: 60px;

    @include breakpoint(tablet) {
      margin-bottom: 95px;
    }

    &-img {
      display: none;
      width: 90%;
      position: relative;
      border-radius: 0 450px 0 0;
      margin-bottom: 45px;
      overflow: hidden;

      @include breakpoint(tablet) {
        display: block;
        float: left;
        width: 40%;
        border-radius: 0 450px 450px 0;
        margin-bottom: 0;
        top: 35px;
      }

      @include breakpoint(tablethor) {
        width: 44%;
        top: 0;
      }

      @include breakpoint(laptop) {
        display: inline-block;
        vertical-align: top;
        border-radius: 0 450px 0 0;
        float: inherit;
        width: 47%;
        top: 0;
      }

      @include breakpoint(desktop) {
        width: 50%;
      }

      &:before, &:after {
        content: ' ';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }

      &:before {
        background: $greyText;
        opacity: 0.29;
        z-index: 1;
      }

      &:after {
        opacity: 0.2;
        background-color: #715420;
        z-index: 2;
      }

      img {
        display: block;
        width: 100%;
        height: auto;
        filter: grayscale(100%);
        border-radius: 0 450px 0 0;
        position: relative;
        z-index: 0;

        @include breakpoint(tablet) {
          border-radius: 0 450px 450px 0;
        }

        @include breakpoint(laptop) {
          border-radius: 0 450px 0 0;
        }
      }
    }

    &-content {
      display: block;
      width: 85%;
      margin: auto;
      text-align: right;
      box-sizing: border-box;

      @include breakpoint(phablet) {
        width: 80%;
      }

      @include breakpoint(tablet) {
        width: 85%;
      }

      @include breakpoint(laptop) {
        display: inline-block;
        vertical-align: top;
        padding-left: 60px;
        width: 48%;
      }

      @include breakpoint(desktop) {
        width: 43%;
        padding-top: 58px;
        padding-left: 80px;
      }

      .title-special-right {
        @include breakpoint(tablethor) {
          width: 40%;
        }

        @include breakpoint(laptop) {
          width: 68%;
        }

        @include breakpoint(mediumdesktop) {
          width: 66%;
        }
      }

      .h4-title {
        margin-right: 35px;

        @include breakpoint(phablet) {
          margin-right: 48px;
        }
      }

      .text {
        margin-left: auto;
        margin-right: 35px;
        margin-bottom: 30px;

        @include breakpoint(phablet) {
          margin-right: 48px;
        }

        @include breakpoint(tablet) {
          width: 45%;
        }

        @include breakpoint(laptop) {
          width: 380px;
        }

        @include breakpoint(desktop) {
          margin-bottom: 45px;
        }
      }
    }

    &-list {
      display: none;

      &.slick-initialized {
        display: block;
      }

      @include breakpoint(phablet) {
        display: flex;
        justify-content: flex-end;
      }

      @include breakpoint(tablet) {
        margin-right: 48px;
      }

      li {
        list-style: none;
        padding: 0 10px;

        @include breakpoint(phablet) {
          flex-basis: 145px;
          max-width: 145px;
          padding: 0;

          &:not(:first-child) {
            margin-left: 20px;
          }
        }

        a {
          display: block;
          height: 100%;
          background: $greyLight;
          box-sizing: border-box;
          font-size: 15px;
          font-weight: bold;
          line-height: normal;
          text-align: center;
          border-radius: 10px;
          padding: 35px 15px;
          transition: all $durationIntermediate $easeOutBack;

          @include breakpoint(phablet) {
            padding: 16px;
          }

          .icon {
            display: block;
            width: 50px;
            height: 50px;
            fill: $greyText;
            margin: auto auto 13px;
            transform: translateY(1px);
            transition: all $durationIntermediate $easeOutBack;
          }

          &:hover {
            color: $orange;
            background: darken($greyLight, 3%);
            transition: all $durationIntermediate $easeOutBack;

            .icon {
              fill: $orange;
              transform: translateY(-1px);
              transition: all $durationIntermediate $easeOutBack;
            }
          }
        }
      }
    }
  }