/* ==========================================================================
   Page / Page Plan du site
   ========================================================================== */

  .sitemap {
    @include wrapper('min');
    position: relative;
    z-index: 5;

    @include breakpoint(phablet) {
      padding: 0 20px;
    }

    ul {
      margin-left: 2px;

      li {
        list-style-type: none;
        font-size: 17px;
        font-weight: 600;
        color: $blackColor;
        line-height: 1.47;
        letter-spacing: normal;
        position: relative;
        padding-left: 20px;

        &:not(:last-child) {
          margin-bottom: 15px;
        }

        &:before {
          content: ' ';
          display: block;
          width: 6px;
          height: 6px;
          background: $blueColor;
          border-radius: 800px;
          position: absolute;
          top: 9px;
          left: 0;
          right: 0;
          margin: auto 0;
        }

        ul {
          margin-top: 10px;
          margin-bottom: 20px;

          li {
            font-size: 16px;
            font-weight: 400;

            &:not(:last-child) {
              margin-bottom: 8px;
            }

            &:before {
              content: ' ';
              display: block;
              width: 6px;
              height: 6px;
              background: transparent;
              border: 1px solid $blueColor;
              border-radius: 800px;
              position: absolute;
              top: 9px;
              left: 0;
              right: 0;
              margin: auto 0;
            }

            ul {
              li {
                font-weight: 300;

                &:not(:last-child) {
                  margin-bottom: 5px;
                }

                &:before {
                  border-color: $orange;
                }
              }
            }
          }
        }
      }
    }

    .products > ul {
      display: flex;
      flex-wrap: wrap;

      li {
        flex-basis: 40%;

        @include breakpoint(tablet) {
          flex-basis: 30%;
        }

        @include breakpoint(laptop) {
          flex-basis: 24%;
        }
      }
    }

    a:hover {
      text-decoration: underline;
    }
  }