/* ==========================================================================
   Components / WYSIWYG
   ========================================================================== */

  /* BLOCK */

  .blockwysiwyg {
    @include wrapper('min');
    margin-bottom: 50px;

    @include breakpoint(tablethor) {
      width: 80%;
      margin-bottom: 65px;
    }

    @include breakpoint(laptop) {
      margin-bottom: 95px;
    }

    &-content {
      margin-left: 15px;
      margin-bottom: 20px;

      @include breakpoint(phablet) {
        margin-left: 50px;
      }

      @include breakpoint(laptop) {
        width: 75%;
      }
    }
  }

/* Block image */

  .wp-block-image {
    @include wrapper('min');
    margin: 0 auto!important;

    @include breakpoint(tablethor) {
      width: 80%;
      max-width: 1000px!important;
    }

    figcaption {
      font-size: 16px;
      font-style: italic;
      color: $blackColor;
    }

    figure {
      margin: 0;
    }

    img {
      display: block;
      margin: 15px auto;
      border-radius: 30px 0!important;
    }

    &.is-style-circle-mask figcaption {
      text-align: center;
    }
  }

  .wysiwyg {
    h1 {

    }

    h2 {

    }

    h3 {

    }

    h4 {

    }

    h5 {

    }

    p {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    b {

    }

    a {
      text-decoration: none;

      &:hover {

      }
    }

    ul {
      margin-bottom: 15px;

      li {
        list-style-type: none;
        color: $blackColor;
        line-height: 1.47;
        letter-spacing: normal;
        position: relative;
        padding-left: 20px;

        &:not(:last-child) {
          margin-bottom: 15px;
        }

        &:before {
          content: ' ';
          display: block;
          width: 6px;
          height: 6px;
          background: $blueColor;
          border-radius: 800px;
          position: absolute;
          top: 11px;
          left: 0;
          right: 0;
          margin: auto 0;
        }
      }
    }

    blockquote {

    }

    .btn {
      margin: 35px 0 20px;
    }
  }