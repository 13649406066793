/* ==========================================================================
   Components / Bandeau des cookies
   ========================================================================== */

#tarteaucitronRoot * {
  line-height: 1;
}

// Bandeau principal
body div#tarteaucitronRoot {

  #tarteaucitronAlertBig {
    background: $whiteColor;
    color: $blackColor;

    #tarteaucitronDisclaimerAlert {
      margin: 10px 0 30px;
      display: block;
      text-align: center;
      font-size: 15px;
      line-height: 1.4;
      padding: 0 30px;

      b {
        font-family: $font-stack-common;
      }

    }

    #tarteaucitronPersonalize, #tarteaucitronPersonalize2, #tarteaucitronAllDenied2, #tarteaucitronCloseAlert, .tarteaucitronDeny {
      display: inline-block;
      margin-left: 10px;
      margin-bottom: 10px;
      text-decoration: none;
      background-color: $whiteColor;
      border: 1px solid $orange;
      min-height: 50px;
      font-size: 10px;
      line-height: 16px;
      font-weight: bold;
      letter-spacing: 0.64px;
      padding: 12px 35px;
      box-sizing: border-box;
      border-radius: 20px;
      color: $orange;
      position: relative;
      text-transform: uppercase;
      overflow: hidden;
      cursor: pointer;
      text-align: left;
      transition: all $duration $easeInOutCirc;

      &:hover {
        background: $orange;
        color: $whiteColor;
        transition: all $duration $easeInOutCirc;

        .tarteaucitronCross::before,
        .tarteaucitronCheck::before,
        .tarteaucitronPlus::before {
          color: white;
        }
      }
    }

    #tarteaucitronCloseAlert {
      background: $whiteColor;
      color: $orange;
      margin-left: 7px;

      &:hover {
        background: $orange;
        color: $whiteColor;
        transition: all $duration $easeInOutCirc;

        .tarteaucitronCross::before,
        .tarteaucitronCheck::before,
        .tarteaucitronPlus::before {
          color: white;
        }
      }
    }
  }

  #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk .tarteaucitronAllow,
  #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk .tarteaucitronDeny,
  .tac_activate .tarteaucitronAllow {
    background: $whiteColor;
    color: black;
    transition: all .6s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }

  .tarteaucitronCross::before {
    content: '\2717';
    display: inline-block;
    color: $orange;
    transition: all $duration $easeInOutCirc;
  }

  .tarteaucitronCheck::before {
    content: '\2713';
    display: inline-block;
    color: $orange;
    transition: all $duration $easeInOutCirc;
  }

  .tarteaucitronPlus::before {
    content: '\271b';
    display: inline-block;
    color: $orange;
    transition: all $duration $easeInOutCirc;
  }

  .tarteaucitronLine .tarteaucitronAllow,
  .tarteaucitronIsAllowed .tarteaucitronDeny {
    opacity: 1 !important;
  }

  #tarteaucitron #tarteaucitronServices #tarteaucitronAllDenied.tarteaucitronIsSelected,
  #tarteaucitron #tarteaucitronServices #tarteaucitronAllDenied2.tarteaucitronIsSelected,
  #tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsDenied .tarteaucitronDeny,
  #tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotRed {
    background: #d7123f !important;
    color: #FFF !important;

    .tarteaucitronCross::before,
    .tarteaucitronCheck::before,
    .tarteaucitronPlus::before {
      color: white;
    }
  }

  #tarteaucitron #tarteaucitronServices #tarteaucitronAllAllowed.tarteaucitronIsSelected,
  #tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsAllowed .tarteaucitronAllow,
  #tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotGreen {
    background: #57b97f !important;
    color: #FFF !important;

    .tarteaucitronCross::before,
    .tarteaucitronCheck::before,
    .tarteaucitronPlus::before {
      color: white;
    }
  }

  #tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotYellow {
    background-color: #fbda26;
  }

  .tarteaucitronAsk {
    .tarteaucitronCross::before,
    .tarteaucitronCheck::before,
    .tarteaucitronPlus::before {
      color: black;
    }
  }
}
