/* ==========================================================================
   Components / Product Card
   ========================================================================== */

  .productcard {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 0 30px;
    background: darken($greyLight, 1%);
    overflow: hidden;
    position: relative;

    &:after {
      content: ' ';
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 0 30px;
      box-shadow:inset 0 0 0 2px $greyLight;
      transition: all $duration $easeInOutBack;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }

    &:before {
      content: ' ';
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 0 30px;
      box-shadow:inset 0 0 0 3px $blueColor;
      opacity: 0;
      transform: scale(0.98);
      transition: all $duration $easeInOutBack;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }

    &:hover {
      &:before {
        opacity: 1;
        transform: scale(1);
        transition: all $duration $easeInOutBack;
      }

      img {
        transform: scale(1.02);
        transition: all $duration $easeInOutSine;
      }
    }

    &-img {
      display: block;
      background: #fcfcfc;
      text-align: center;
      overflow: hidden;

      img {
        display: block;
        width: 100%;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        margin: auto;
        transition: all $duration $easeInOutSine;
      }
    }

    &-text {
      display: block;
      padding: 30px;
      height: 100%;
      min-height: 100%;
      box-sizing: border-box;
    }

    &-categories {
      display: none;
      margin-bottom: 13px;

      li {
        display: inline-block;
        list-style-type: none;
        font-size: 10px;
        font-family: $font-stack-title;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.015rem;
        text-transform: uppercase;
        color: $whiteColor;
        padding: 6px 14px;
        border-radius: 5px 0 5px 0;
        margin-right: 6px;
      }
    }

    &-title {
      @extend .h4-title;
      font-weight: 500;
      margin-bottom: 10px;
    }

    &-desc {
      letter-spacing: -0.01rem;
    }
  }