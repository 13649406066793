/* ==========================================================================
   Components / Card
   ========================================================================== */

  .card {
    display: flex;
    flex-direction: column;
    border-radius: 0 30px;
    overflow: hidden;
    position: relative;
    cursor: pointer;

    &:before {
      content: ' ';
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 0 30px;
      box-shadow:inset 0 0 0 2px lighten($blueColor, 5%);
      opacity: 0;
      transition: all $duration $easeInOutBack;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }

    &:hover {
      &:before {
        opacity: 1;
        transition: all $duration $easeInOutBack;
      }

      img {
        transform: scale(1.03);
        opacity: 0.8;
        transition: all $duration $easeInOutSine;
      }

      .btn-more {
        color: $orange;
        transition: all $duration $easeInOutCirc;

        .icon {
          fill: $blackColor;
          transform: translatex(5px);
          transition: all $duration $easeInOutQuint;
        }
      }
    }

    &:nth-child(odd) {
      border-radius: 30px 0;

      &:before {
        border-radius: 30px 0;
      }
    }

    &-img {
      display: block;
      background: $orange;
      max-height: 195px;
      overflow: hidden;

      @include breakpoint(tablet) {
        max-height: inherit;
      }

      img {
        display: block;
        width: 100%;
        height: auto;
        transition: all $duration $easeInOutSine;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      background: $greyLight;
      box-sizing: border-box;
      padding: 25px 30px 35px;
      flex-grow: 1;
    }

    &-tags {
      max-width: 100%;

      a {
        display: inline-block;
        font-family: $font-stack-special;
        font-size: 12px;
        font-weight: 900;
        line-height: 1;
        letter-spacing: normal;
        color: $orange;
        margin-right: 10px;
        margin-bottom: 0;

        &:before {
          content: '#';
        }

        &:hover {
          color: $blueColor;
        }
      }
    }

    &-date {
      display: block;
      max-width: 100%;
      font-family: $font-stack-special;
      font-size: 12px;
      font-weight: 900;
      line-height: 2.33;
      letter-spacing: normal;
      margin-right: 10px;
      margin-bottom: 12px;
    }

    &-title {
      display: block;
      max-width: 100%;
      font-family: $font-stack-title;
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.35;
      letter-spacing: -0.01rem;
      margin-bottom: 15px;
    }

    &-excerpt {
      display: block;
      max-width: 100%;
      flex-grow: 1;
      margin-bottom: 20px;
    }
  }