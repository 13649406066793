/* ==========================================================================
   Layout / Suggestion de produits
   ========================================================================== */

  .suggestproducts {
    display: block;
    margin-bottom: 50px;

    @include breakpoint(tablethor) {
      margin-bottom: 100px;
    }

    &-title {
      @extend .title-section;
      margin-bottom: 30px;

      @include breakpoint(tablethor) {
        margin-bottom: 50px;
      }
    }

    &-content {
      @include wrapper();
      width: 90%;
      max-width: 1150px;

      @include breakpoint(phablet) {
        display: flex;
        justify-content: center;
      }

      @include breakpoint(tablet) {
        width: 85%;
      }

      .slick-prev, .slick-next {
        top: 20%;
      }

      .slick-track {
        display: flex;
      }

      .slick-slide {
        display: inline-block;
        height: auto!important;
      }

      .productcard {
        margin: 5px;
        height: auto!important;

        @include breakpoint(phablet) {
          margin: 0 8px;
        }

        @include breakpoint(tablet) {
          margin: 0 12px;
        }
      }

      .slick-prev, .slick-next {
        background-color: $greyLight;
        background-size: 50% auto;
        background-position: 10px center;
        border-radius: 80px;
        padding: 18px;
        top: calc(22% - 20px);

        @include breakpoint(phablet) {
          padding: 20px;
          background-position: 12px center;
        }

        &.slick-disabled {
          display: none!important;
        }
      }

      .slick-next {
        right: -5px;
      }

      .slick-prev {
        left: -5px;
      }
    }
  }