/* ==========================================================================
   Layout / Block Engagements
   ========================================================================== */

  .commitments {
    @include wrapper('min');
    margin-bottom: 95px;

    .title-special {
      margin-bottom: 30px;

      @include breakpoint(tablethor) {
        margin-bottom: 50px;
      }
    }

    &-wrapper {
      @include breakpoint(tablethor) {
        display: flex;
        justify-content: space-between;
      }
    }

    &-cards {
      display: none;
      border-radius: 30px 0;
      margin-bottom: 15px;
      overflow: hidden;

      &.slick-initialized {
        display: block;
      }

      @include breakpoint(phablet) {
        display: flex;
        flex-wrap: wrap;
        border-radius: 0;
        margin-bottom: 0;
        overflow: auto;
      }

      @include breakpoint(tablethor) {
        flex-basis: calc(65% - 20px);
        width: calc(65% - 20px);
      }

      @include breakpoint(laptop) {
        flex-basis: calc(100% - 428px);
        width: calc(100% - 428px);
      }

      .slick-track {
        display: block;
      }

      .slick-next, .slick-prev {
        transform: translate(180deg);
        background: url('images/arrow-white.svg') center center/100% auto no-repeat;
      }

      .slick-prev {
        top: 10%;
        left: 0;
        right: 0;
        margin: auto;
      }

      .slick-next {
        top: inherit;
        bottom: 10%;
        left: 0;
        right: 0;
        margin: auto;
      }
    }

    &-push {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      background: $darkBlue;
      border-radius: 0 30px;
      color: $whiteColor;
      padding: 40px 30px 35px;
      box-sizing: border-box;
      text-align: center;
      margin-bottom: 20px;
      overflow: hidden;

      @include breakpoint(phablet) {
        padding: 40px 35px 30px;
        border-radius: 20px;
      }

      @include breakpoint(tablet) {
        padding: 50px 45px 40px;
      }

      @include breakpoint(tablethor) {
        margin-left: 20px;
        flex-basis: 35%;
        width: 35%;
        padding: 35px 30px 35px;
        border-radius: 0 30px;
      }

      @include breakpoint(laptop) {
        flex-basis: 408px;
        width: 408px;
        padding: 15px 35px;
      }

      .title {
        display: block;
        width: 100%;
        max-height: 100%;
        font-family: $font-stack-title;
        font-size: 20px;
        font-weight: 500;
        line-height: 1.39;
        letter-spacing: normal;
        color: $whiteColor;
        margin-bottom: 15px;

        @include breakpoint(laptop) {
          font-size: 23px;
          margin-top: 20px;
          margin-bottom: 28px;
        }

        @include breakpoint(desktop) {
          margin-top: 50px;
        }
      }

      .text {
        width: 100%;

        p {
          font-size: 16px;

          @include breakpoint(laptop) {
            font-size: 18px;
            letter-spacing: -0.02rem;
          }
        }

        & + .btn {
          margin-top: 30px;

          @include breakpoint(tablet) {
            margin-top: 20px;
          }

          @include breakpoint(desktop) {
            margin-top: 60px;
          }
        }
      }
    }

    &-card {
      display: block;
      border: 0!important;
      position: relative;
      overflow: hidden;

      @include breakpoint(phablet) {
        flex-basis: calc(50% - 10px);
        width: calc(50% - 10px);
        border-radius: 30px 0;
        margin-bottom: 15px;

        &:nth-child(2), &:nth-child(3) {
          border-radius: 0 30px;
        }

        &:nth-child(even) {
          margin-left: 8px;
        }

        &:nth-child(odd) {
          margin-right: 8px;
        }
      }

      @include breakpoint(tablet) {
        margin-bottom: 20px;

        &:nth-child(even) {
          margin-left: 10px;
        }

        &:nth-child(odd) {
          margin-right: 10px;
        }
      }

      &:before {
        content: ' ';
        display: block;
        width: 100%;
        height: 100%;
        background-color: $blackColor;
        opacity: 0.5;
        transition: all $durationSmall $easeOutSine;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }

      img {
        display: block;
        width: auto;
        height: auto;
        max-width: 100%;
        //min-height: 100%;
        min-width: 100%;
        position: relative;
        transition: all $durationSmall $easeOutSine;
        margin: auto;
        z-index: 0;
      }

      .title {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        height: 100%;
        font-family: $font-stack-title;
        transition: all $durationSmall $easeOutSine;
        font-size: 23px;
        font-weight: 500;
        line-height: 1.39;
        letter-spacing: normal;
        color: $whiteColor;
        margin-bottom: 20px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;

        h3 {
          display: block;
          width: 85%;
          margin: 0;

          @include breakpoint(laptop) {
            width: 55%;
          }

          @include breakpoint(desktop) {
            width: 45%;
          }
        }
      }
    }

    a.commitments-card:hover {
      img {
        transform: scale(1.05);
        transition: all $durationSmall $easeOutSine;
      }
    }
    a.commitments-card:nth-child(1):hover, a.commitments-card:nth-child(4):hover {
      &:before {
        background: darken($blueColor, 5%);
        opacity: 0.7;
        transition: all $durationSmall $easeOutSine;
      }
    }
    a.commitments-card:nth-child(2):hover, a.commitments-card:nth-child(3):hover {
      &:before {
        background: darken($orange, 5%);
        opacity: 0.7;
        transition: all $durationSmall $easeOutSine;
      }
    }
  }
