/* ==========================================================================
   Layout / Top Navigation
   ========================================================================== */

  .navigation {
    display: flex;
    background: $darkBlue;
    height: 70px;
    position: relative;

    @include breakpoint(tablet) {
      height: 75px;
    }

    @include breakpoint(laptop) {
      height: 115px;
    }

    &-menu {
      flex-grow: 1;
      text-align: right;
      padding: 17px 0 0;

      @include breakpoint(tablet) {
        padding: 19px 0 0;
      }

      @include breakpoint(laptop) {
        padding: 20px 0 0;
      }

      .utils {
        display: table;
        box-sizing: border-box;
        text-align: right;
        margin: 0 50px 0 auto;

        @include breakpoint(phablet) {
          position: relative;
        }
      }
    }

    &-mask {
      display: block;
      width: 100%;
      height: 70px;
      background: $darkBlue;
      transition: all $durationSmall $easeInOutQuint;
      position: absolute;
      top: 0;
      right: -100%;
      z-index: 6;

      @include breakpoint(phablet) {
        width: 375px;
      }

      @include breakpoint(tablet) {
        width: 365px;
        height: 75px;
      }

      @include breakpoint(tablethor) {
        width: 385px;
      }

      @include breakpoint(laptop) {
        display: none;
      }

      &.open {
        right: 0;
        transition: all $durationSmall $easeInOutCirc;
      }
    }

    /* SEARCH BOX */
    &-searchbox {
      display: block;
      visibility: hidden;
      opacity: 0;
      margin-bottom: 9px;
      transition: all $duration $easeInOutCirc;
      position: absolute;
      left: 20px;
      top: 20px;
      z-index: 6;

      @include breakpoint(phablet) {
        opacity: 1.0;
        visibility: visible;
        position: relative;
        left: inherit;
        top: -3px;
        right: 13%;
      }

      @include breakpoint(tablet) {
        top: -2px;
        right: 18%;
      }

      @include breakpoint(tablethor) {
        right: 22%;
      }

      @include breakpoint(laptop) {
        right: 0;
        top: 0;
        padding-right: 70px;
        margin-bottom: 14px;
      }

      &.show {
        visibility: visible;
        opacity: 1.0;
        transition: all $duration $easeInOutCirc;
      }

      input {
        display: table;
        background: $whiteColor;
        border: 0;
        font-size: 15px;
        width: 205px;
        box-sizing: border-box;
        font-family: $font-stack-common;
        font-weight: 500;
        line-height: normal;
        color: $greyText;
        padding: 11px 25px 12px;
        border-radius: 22px;
        margin-top: 0;

        @include breakpoint(smallMobile) {
          width: 170px;
        }

        @include breakpoint(phablet) {
          width: 200px;
        }

        @include breakpoint(tablet) {
          width: 240px;
        }

        @include breakpoint(tablethor) {
          width: 255px;
        }

        @include breakpoint(laptop) {
          padding: 9px 20px 10px;
          width: 220px;
        }
      }

      .search-submit {
        display: block;
        position: absolute;
        top: 8px;
        right: 10px;

        @include breakpoint(laptop) {
          top: 6px;
          right: 85px;
        }

        .icon {
          display: block;
          width: 21px;
          height: 22px;
          fill: $greyText;
          transition: all $duration $easeInOutCirc;
        }

        &:hover {
          .icon {
            fill: $orange;
            transition: all $duration $easeInOutCirc;
          }
        }
      }
    }

    /* LANGUAGE */
    &-lang {
      display: flex;
      flex-direction: column;
      width: 50px;
      position: absolute;
      cursor: pointer;
      top: 28px;
      right: 75px;
      z-index: 7;

      @include breakpoint(smallMobile) {
        right: 72px;
      }

      @include breakpoint(phablet) {
        top: 10px;
        right: 120%;
      }

      @include breakpoint(tablet) {
        right: 125%;
        z-index: 4;
      }

      @include breakpoint(tablethor) {
        right: 130%;
      }

      @include breakpoint(laptop) {
        right: 0;
        top: 10px;
        z-index: 8;
      }

      li {
        list-style-type: none;
        display: block;
        font-size: 15px;
        font-weight: normal;
        line-height: normal;
        letter-spacing: normal;
        max-height: 0;
        padding-left: 10px;
        color: $whiteColor;
        text-align: left;
        text-transform: uppercase;
        margin-bottom: 0;
        transition: all $durationIntermediate $easeInOutExpo;
        overflow: hidden;
        order: 1;

        a {
          display: table;
          width: 20px;
          padding: 5px 0 2px;
          color: $whiteColor;
          margin-top: 3px;
          border-top: 1px solid $whiteColor;

          &:hover {
            color: $blueColor;
          }
        }

        &.show {
          max-height: 35px;
          transition: all $durationIntermediate $easeInOutExpo;
        }

        &.current {
          opacity: 1;
          order: 0;
          max-height: 20px;

          &:after {
            content: ' ';
            display: inline-block;
            width: 10px;
            height: 10px;
            background: url('images/arrow-white.svg') top right /10px 10px no-repeat;
            margin-left: 6px;
          }
        }
      }
    }

    .logo-fixed {
      display: none;
      position: absolute;
      top: 0;
      left: 30px;

      .header-logo {
        width: 100px!important;

        @include breakpoint(tablet) {
          width: 115px !important;
          padding: 9px 30px 0;
        }

        @include breakpoint(tablethor) {
          width: 120px !important;
          padding: 9px 40px 0;
        }

        @include breakpoint(desktop) {
          width: 140px !important;
          padding: 10px 40px 0;
        }
      }
    }

    /* Menu Sticky */
    &.sticky {
      width: 100%;
      height: 65px;
      background: $whiteColor!important;
      box-shadow: -3px 0 15px 0 rgba(0,0,0,0.2);
      transition: all $duration $easeInOutSine;
      border-radius: 0 0 0 100px;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 10;

      @include breakpoint(phablet) {
        height: 70px;
      }

      @include breakpoint(tablet) {
        height: 75px;
      }

      @include breakpoint(desktop) {
        height: 90px;
      }

      &.hide {
        top: -110px;
        transition: all $duration $easeInOutSine;
      }

      .navigation-lang {
        top: 23px;
        right: 90px;

        @include breakpoint(phablet) {
          top: 10px;
          right: 120%;
        }

        @include breakpoint(tablet) {
          right: 128%;
        }

        @include breakpoint(tablethor) {
          right: 135%;
        }

        li {
          color: $darkBlue;

          a {
            color: $darkBlue;
          }

          &:after {
            background: url('images/arrow-dark-blue.svg') top right/10px 10px no-repeat;
          }
        }
      }

      .navigation-searchbox {
        @include breakpoint(phablet) {
          margin: 0 15px;
        }

        @include breakpoint(tablet) {
          margin: 0;
        }

        input {
          background: darken($greyLight, 1%);
        }
      }

      .logo-fixed {
        display: block;
      }

      .utils {
        @include breakpoint(laptop) {
          display: none;
        }
      }

      .navigation-menu {
        @include breakpoint(desktop) {
          padding: 28px 0 0;
        }
      }

      .nav-menu>.menu-item {
        @include breakpoint(laptop) {
          margin-left: 58px;
        }

        a {
          @include breakpoint(laptop) {
            color: $darkBlue;
            padding: 0 0 31px 0;
          }

          @include breakpoint(desktop) {
            padding: 0 0 38px 0;
          }
        }
      }

      .burger {
        top: 20px;

        @include breakpoint(phablet) {
          top: 22px;
        }

        @include breakpoint(tablet) {
          top: 25px;
        }

        &-line {
          background: $darkBlue;
        }
      }
    }
  }
