/* ==========================================================================
   Layout / Block World Team Map
   ========================================================================== */

  .worldmap {
    @include wrapper('min');
    margin-bottom: 40px;
    position: relative;
    z-index: 4;

    @include breakpoint(tablethor) {
      margin-bottom: 90px;
    }

    .title-special-right {
      margin-bottom: 10px;

      @include breakpoint(tablethor) {
        margin-bottom: 30px;
      }
    }

    &-subtitle {
      display: none;
      text-align: right;
      padding-right: 30px;
      margin-bottom: 35px;

      @include breakpoint(phablet) {
        display: block;
        padding-right: 50px;
      }
    }

    .map-svg {
      display: none;
      position: relative;
      z-index: 1;

      @include breakpoint(phablet) {
        display: block;
      }
    }

    &-representants {
      display: none;
      width: 100%;
      height: 100%;

      @include breakpoint(tablet) {
        max-width: 80%;
        margin: auto;
      }

      @include breakpoint(tablethor) {
        max-width: 70%;
        margin: auto;
      }

      @include breakpoint(laptop) {
        display: block;
        max-width: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }

      .cardrepres {
        display: block;
        width: auto;
        background: $whiteColor;
        box-shadow: -3px 0 15px 0 rgba(0,0,0,0.1);
        border-radius: 30px 0;
        margin: 15px;
        overflow: hidden;
        z-index: 1;

        @include breakpoint(phablet) {
          display: flex;
          margin: 15px 10px;
        }

        @include breakpoint(laptop) {
          display: flex;
          position: absolute;
          min-width: 485px;
          max-width: 485px;
          margin: 0;
          opacity: 0;
          visibility: hidden;
          transform: translateY(50px);
          transition: all $duration $easeInOutBack;
        }

        &.show {
          opacity: 1.0;
          visibility: visible;
          transform: translateY(0px);
          transition: all $duration $easeInOutBack;
        }

        &-img {
          display: block;
          width: 100%;
          max-height: 245px;
          overflow: hidden;

          @include breakpoint(phablet) {
            width: 200px;
            flex-basis: 200px;
          }

          img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        &-text {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          box-sizing: border-box;
          padding: 30px 25px;
          text-align: center;

          @include breakpoint(phablet) {
            width: calc(100% - 200px);
            flex-basis: calc(100% - 200px);
            padding: 20px 25px;
          }

          @include breakpoint(tablet) {
            padding: 20px 40px;
          }

          @include breakpoint(tablethor) {
            padding: 20px 60px;
          }

          @include breakpoint(laptop) {
            padding: 20px 40px;
          }

          .btn-main {
            width: 90%;
            padding: 10px 25px 12px;
            min-width: inherit;

            @include breakpoint(phablet) {
              width: auto;
              padding: 10px 50px 12px;
            }

            @include breakpoint(laptop) {
              width: 90%;
              padding: 10px 25px 12px;
            }
          }
        }

        &-name {
          display: block;
          max-width: 150px;
          font-family: $font-stack-title;
          font-size: 20px;
          font-weight: 500;
          line-height: 1.3;
          text-align: center;
          margin-bottom: 10px;
        }

        &-close {
          display: none;
          position: absolute;
          top: 10px;
          right: 10px;
          cursor: pointer;

          .icon {
            font-size: 20px;
            font-family: $font-stack-title;
            font-weight: 500;
            color: $orange;
            transition: all $duration $easeInOutCirc;
          }

          .text {
            display: none;
          }

          &:hover .icon {
            color: $blueColor;
            transition: all $duration $easeInOutCirc;
          }

          @include breakpoint(laptop) {
            display: block;
          }
        }

        &-function {
          display: block;
          width: 100%;
          font-size: 18px;
          font-weight: 300;
          line-height: 1.3;
          letter-spacing: normal;
          text-align: center;
          color: $orange;
          margin-bottom: 20px;
        }
      }

      #representant {
        &-latinamerica { left: 0; top: 65%;}
        &-europe { top: 25%; left: 2%; }
        &-africa { left: 40%; top: 30%;}
        &-asia { left: 60%; top: 60%;}
      }

      .slick-prev, .slick-next {
        background-color: $whiteColor;
        background-size: 50% auto;
        background-position: 10px center;
        box-shadow: -3px 0 15px 0 rgba(0,0,0,0.1);
        border-radius: 80px;
        padding: 18px;
        top: 125px;

        @include breakpoint(phablet) {
          top: calc(50% - 20px);
          padding: 20px;
        }
      }

      .slick-next {
        right: 0;

        @include breakpoint(phablet) {
          right: -10px;
        }
      }

      .slick-prev {
        left: 0;

        @include breakpoint(phablet) {
          left: -10px;
        }
      }
    }
  }