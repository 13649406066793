/* ==========================================================================
   Layout / Block Chiffres Clés
   ========================================================================== */

  .keynumbersblock {
    @include wrapper('min');
    margin-bottom: 50px;

    @include breakpoint(tablethor) {
      margin-bottom: 100px;
    }

    .title-special {
      margin-bottom: 35px;

      @include breakpoint(tablethor) {
        margin-bottom: 50px;
      }
    }

    &-numbers {
      display: none;

      &.slick-initialized {
        display: block;
      }

      @include breakpoint(phablet) {
        display: flex;
        flex-wrap: wrap;
      }

      .keycard {
        @include breakpoint(phablet) {
          flex-basis: calc(50% - 10px);
          width: calc(50% - 10px);
          margin-right: 20px;

          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            flex-basis: calc(35% - 10px);
            width: calc(35% - 10px);
          }

          &:nth-child(2n) {
            margin-right: 0;
          }
        }

        @include breakpoint(laptop) {
          flex-basis: calc(24.5% - 10px);
          width: calc(24.5% - 10px);

          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            flex-basis: calc(16.5% - 10px);
            width: calc(16.5% - 10px);
          }

          &:nth-child(2n) {
            margin-right: 20px;
          }

          &:nth-child(3n) {
            margin-right: 20px;
          }

          &:nth-child(4n) {
            margin-right: 0;
          }
        }
      }
    }
  }