/* ==========================================================================
   Layout / Block Témoignages
   ========================================================================== */

  .blocktestimonies {
    @include wrapper();
    margin-bottom: 60px;

    @include breakpoint(tablet) {
      margin-bottom: 90px;
    }

    @include breakpoint(desktop) {
      margin-bottom: 110px;
    }

    &-title {
      @extend .title-section;
      margin-bottom: 5px;

      @include breakpoint(tablethor) {
        margin-bottom: 20px;
      }
    }

    &-slider {
      display: block;
      max-width: 735px;
      margin: auto;

      .slick-next, .slick-prev {
        width: 30px;
        height: 30px;
        background: url('images/arrow-slide.svg') center center/30px auto no-repeat;
        top: inherit;
        bottom: 0;

        &:not(.slick-disabled):hover {
          background-image: url('images/arrow-slide-orange.svg');
        }
      }

      .slick-next {
        right: 10px;
      }

      .slick-prev {
        left: inherit;
        right: 60px;
      }
    }
  }