/* ==========================================================================
   Layout / Block Espèces
   ========================================================================== */

  .speciesblock {
    display: block;
    width: 100%;
    background: $greyLight;
    border-radius: 0 0 150px 0;
    padding: 45px 0 35px;
    margin-bottom: 60px;

    @include breakpoint(phablet) {
      border-radius: 0 0 265px 0;
      padding: 55px 0 35px;
    }

    @include breakpoint(tablet) {
      margin-bottom: 80px;
    }

    @include breakpoint(tablethor) {
      padding: 70px 0 40px;
      border-radius: 0 0 370px 0;
    }

    @include breakpoint(laptop) {
      padding: 85px 0 30px;
    }

    @include breakpoint(desktop) {
      padding: 90px 0;
      margin-bottom: 80px;
      border-radius: 0 0 610px 0;
    }

    &-wrapper {
      @include wrapper();

      @include breakpoint(phablet) {
        padding: 0 10px;
      }

      @include breakpoint(tablet) {
        padding: 0 30px;
      }

      @include breakpoint(tablethor) {
        display: flex;
        justify-content: center;
        padding: 0;
      }
    }

    &-intro {
      display: block;
      margin-bottom: 40px;

      @include breakpoint(phablet) {
        margin-bottom: 60px;
      }

      @include breakpoint(tablethor) {
        flex-basis: 45%;
        width: 45%;
      }

      @include breakpoint(laptop) {
        flex-basis: 435px;
        width: 435px;
        margin: 0 6% 0 2%;
      }

      .wysiwyg {
        @include breakpoint(tablet) {
          margin-left: 50px;
        }
      }
    }

    &-list {
      @include breakpoint(tablethor) {
        flex-basis: 55%;
        width: 55%;
      }

      @include breakpoint(laptop) {
        margin-top: 20px;
        flex-basis: calc(85% - 435px);
        width: calc(85% - 435px);
      }

      @include breakpoint(desktop) {
        flex-basis: calc(90% - 435px);
        width: calc(90% - 435px);
      }
    }

    &-elem {
      &:not(:last-child) {
        margin-bottom: 20px;

        @include breakpoint(phablet) {
          margin-bottom: 30px;
        }

        @include breakpoint(tablethor) {
          margin-bottom: 15px;
        }

        @include breakpoint(desktop) {
          margin-bottom: 40px;
        }
      }
    }

    &-cards {
      display: none;
      flex-wrap: wrap;
      justify-content: center;

      &.slick-initialized {
        display: flex;
      }

      @include breakpoint(tablethor) {
        display: flex;
      }

      .speciecard {
        background: $whiteColor;
        margin: 0 10px 25px;

        @include breakpoint(phablet) {
          margin: 0 15px 25px;
        }

        @include breakpoint(tablethor) {
          margin: 0 10px 30px;
          flex-basis: 110px;
          width: 110px;
        }

        @include breakpoint(laptop) {
          margin: 0 15px 25px;
        }

        @include breakpoint(desktop) {
          margin: 0 10px 30px;
        }

        &-name {
          font-size: 15px;
          padding: 10px 15px 30px;
          height: 58px;
        }
      }

      .slick-prev, .slick-next {
        background-color: $greyLight;
        background-size: 50% auto;
        background-position: center center;
        border-radius: 80px;
        padding: 18px;
        top: 20%;

        @include breakpoint(phablet) {
          padding: 20px;
        }
      }

      .slick-next {
        right: -10px;
      }

      .slick-prev {
        left: -10px;
      }
    }

    &-title {
      display: block;
      font-family: $font-stack-title;
      font-size: 23px;
      font-weight: 600;
      line-height: 1.17;
      letter-spacing: normal;
      text-align: center;
      color: $orange;
      margin-bottom: 30px;
    }
  }