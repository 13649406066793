/* ==========================================================================
   Components / Team Card
   ========================================================================== */

  .teamcard {
    display: block;
    background: $whiteColor;
    border-radius: 30px 0;
    overflow: hidden;

    &-img {
      display: block;

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    &-text {
      display: block;
      box-sizing: border-box;
      padding: 30px 30px 35px;
      text-align: center;
    }

    &-name {
      font-family: $font-stack-title;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.2;
      text-align: center;
      margin-bottom: 20px;
    }

    &-job {
      font-size: 17px;
      font-weight: 300;
      line-height: 1.1;
      color: $orange;
    }
  }