/* ==========================================================================
   Layout / Header
   ========================================================================== */

  .header {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 30px;

    @include breakpoint(phablet) {
      margin-bottom: 60px;
    }

    @include breakpoint(laptop) {
      margin-bottom: 90px;
    }

    .form-logo {
      content: ' ';
      display: block;
      width: 500px;
      height: 100%;
      min-height: 100vh;
      position: absolute;
      top: 0;
      left: -1px;
      z-index: 3;

      @include breakpoint(smallMobile) {
        width: 135%;
      }

      @include breakpoint(phablet) {
        width: 525px;
      }

      @include breakpoint(tablet) {
        width: 685px;
      }

      @include breakpoint(tablethor) {
        width: 820px;
      }

      @include breakpoint(laptop) {
        width: 900px;
        height: 36.1em;
        transform: rotate(1deg);
        top: -3px;
        left: -11px;
      }

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    .fadebg {
      display: block;
      opacity: 0;
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      visibility: hidden;
      background: rgba(190, 190, 190, 0.9);
      transition: all $duration $easeInOutSine;
      z-index: 6;

      &.moreIndex {
        z-index: 7;
      }

      &.show {
        visibility: visible;
        opacity: 1.0;
        transition: all $duration $easeInOutSine;

        @include breakpoint(laptop) {
          display: none;
        }
      }
    }

    &-logo {
      display: block;
      width: 130px;
      padding: 9px 20px 7px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 4;

      @include breakpoint(smallMobile) {
        width: 110px;
        padding: 10px 20px 10px;
      }

      @include breakpoint(phablet) {
        width: 125px;
        padding: 10px 25px 0;
      }

      @include breakpoint(tablet) {
        width: 165px;
        padding: 12px 30px 0;
      }

      @include breakpoint(tablethor) {
        width: 185px;
        padding: 19px 40px 0;
      }

      @include breakpoint(laptop) {
        width: auto;
        padding: 22px 38px;
      }

      img {
        display: block;
        max-width: 100%;
        height: auto;
        opacity: 1!important;
      }
    }

    &-subtitle {
      display: block;
      font-weight: 800;
      font-size: 16px;
      color: $whiteColor;
      margin-left: 40px;
      margin-bottom: -25px;
      position: relative;
      z-index: 3;

      @include breakpoint(laptop) {
        margin-left: 10px;
      }
    }

    &-title {
      display: flex;
      align-items: center;
      width: 80%;
      max-width: 1170px;
      padding: 50px 0 20px;
      flex-grow: 1;
      @extend .h1-title;
      line-height: 1.2;
      color: $whiteColor;
      position: relative;
      left: 10%;
      z-index: 3;

      @include breakpoint(phablet) {
        left: 15%;
      }

      @include breakpoint(tablet) {
        left: 10%;
      }
    }

    &-background {
      display: block;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: top center;
      background-repeat: no-repeat;
      position: absolute;
      overflow: hidden;
      top: 0;
      left: 0;
      z-index: 0;

      &:before {
        content: ' ';
        display: block;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.35);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }

      &:after {
        content: ' ';
        display: none;
        width: 100%;
        height: 100%;
        background: rgba(151, 137, 113, 0.5);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
      }

      video {
        display: block;
        object-fit: cover;
        min-width: 100%;
        min-height: 100%;
        max-width: none;
      }
    }

    &-wrapper {
      @include wrapper('min');
      width: 90%;
      padding-left: 20px;
      padding-bottom: 55px;

      @include breakpoint(phablet) {
        width: 90%;
        padding-left: 50px;
      }

      @include breakpoint(tablet) {
        width: 90%;
        padding-left: 80px;
      }

      @include breakpoint(tablethor) {
        width: 95%;
        padding-left: 145px;
        min-height: 380px;
      }

      @include breakpoint(laptop) {
        padding-left: 105px;
        padding-bottom: 75px;
        min-height: 405px;
      }

      @include breakpoint(desktop) {
        max-width: 1280px;
        width: 82%;
      }

      @include breakpoint(mediumdesktop) {
        width: 80%;
        max-width: $wrapperMin;
        padding-left: 15px;
      }

      @include breakpoint(largedesktop) {
        width: 95%;
        padding-left: 25px;
      }

      .breadcrumb {
        font-family: $font-stack-title;
        font-weight: 500;
        margin-top: 5px;
        margin-left: 80px;
        position: relative;
        z-index: 5;

        @include breakpoint(phablet) {
          margin-top: 0;
          margin-left: 65px;
          margin-bottom: 10px;
        }

        @include breakpoint(tablet) {
          margin-left: 95px;
        }

        @include breakpoint(tablethor) {
          margin-left: 100px;
          margin-bottom: 15px;
        }

        @include breakpoint(laptop) {
          margin-left: 105px;
          margin-bottom: 95px;
        }

        @include breakpoint(mediumdesktop) {
          margin-left: 115px;
        }

        @include breakpoint(largedesktop) {
          margin-left: 0;
        }

        a {
          color: $whiteColor;
        }

        ul li:last-child {
          font-weight: 500;
        }
      }

      .header-title {
        width: 100%;
        left: 5px;
      }
    }

    &-text {
      display: block;
      width: 95%;
      max-width: 1045px;
      color: $whiteColor;
      font-size: 17px;
      font-weight: 300;
      line-height: 1.5;
      position: relative;
      left: 5px;
      z-index: 3;

      @include breakpoint(tablet) {
        line-height: 1.6;
        font-weight: 400;
      }
    }

    &-metas {
      display: block;
      width: 100%;
      margin: 15px auto;
      position: relative;
      z-index: 5;

      @include breakpoint(tablethor) {
        width: 85%;
      }

      @include breakpoint(desktop) {
        width: 100%;
        margin: 20px 0;
      }

      li {
        list-style-type: none;
        display: inline-block;
        color: darken($greyLight, 60%);
        font-weight: 400;
        text-transform: uppercase;
        font-size: 16px;
        margin-right: 20px;
        margin-bottom: 5px;

        .icon {
          display: inline-block;
          width: 16px;
          height: 16px;
          fill: $orange;
          margin-right: 10px;
          position: relative;
          top: 2px;
        }

        &.tags {
          a {
            &:not(:last-child) {
              margin-right: 10px;
            }

            &:before {
              content: '#';
            }

            &:hover {
              color: $orange;
            }
          }

          span {
            &:not(:last-child) {
              margin-right: 10px;
            }
          }
        }

        &.share {
          ul {
            display: inline-block;
            position: relative;
            top: 4px;
            left: 5px;

            li {
              margin-right: 5px;

              &:last-child {
                position: relative;
                bottom: 7px;
              }
            }
          }
        }
      }
    }

    &.hasNoImgBg  {
      @include breakpoint(phablet) {
        margin-bottom: 30px;
      }

      @include breakpoint(laptop) {
        margin-bottom: 50px;
      }

      .header-wrapper {
        min-height: inherit;
        padding: 0 20px;

        .breadcrumb {
          margin-bottom: 15px;
          margin-left: 0;

          @include breakpoint(phablet) {
            margin-left: 95px;
          }

         @include breakpoint(tablet) {
           margin-left: 165px;
         }

          @include breakpoint(tablethor) {
            margin-left: 245px;
          }

          @include breakpoint(laptop) {
            margin-left: 200px;
          }

          @include breakpoint(desktop) {
            margin-left: 6em;
          }

          @include breakpoint(mediumdesktop) {
            margin-left: 4.5em;
          }

          @include breakpoint(largedesktop) {
            margin-left: 7em;
          }

          @include breakpoint(1745px) {
            margin-left: 0;
          }

          a {
            color: $greyText;
          }
        }

        .header-title {
          width: 100%;
          margin: auto;
          padding-top: 0;
          left: 0;

          @include breakpoint(tablet) {
            padding-top: 50px;
          }

          @include breakpoint(tablethor) {
            width: 85%;
          }

          @include breakpoint(desktop) {
            width: 100%;
            margin: 0;
          }
        }
      }

      .header-background {
        display: none;
      }

      .header-title {
        color: $greyText;
        padding-bottom: 0;
      }
    }
  }